import React, { Component } from 'react';
import './SideMenuComponent.css';

class SideMenuComponent extends Component {
    
    render(){
        return(
            <div id={"controls_1"} className={this.props.isSettings ? "wrapper-sidemenu-settings": "wrapper-sidemenu"}>
                {
                    this.props.data.length !== 0 ? 
                        this.props.data.map((el, idx) => {
                            return (
                                <div 
                                    key={idx+1} 
                                    className={"font-menu-style"} 
                                    id={'1_'+idx.toString()}
                                >
                                    <span style={{
                                        position: 'relative', 
                                        left: this.props.isSettings ? 105 : 25, 
                                        top: this.props.isSettings ? 25 : 10
                                    }}>
                                        {el.title}
                                    </span>
                                </div>
                            )
                        })
                    : null
                }
            </div>
        )
    }
    
}

export default SideMenuComponent;