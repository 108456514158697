import React from 'react';
import './BigSliderComponent.css';
import ItemBigSliderComponent from '../ItemBigSlider/ItemBigSliderComponent';

function BigSliderComponent(props){
    return (
        <div style={{ width: (1120 * props.items.length) + 'px' }} id="controls_1" className="wrapper-big-slider">
            {
                props.items.map((elm, idx)=> {
                    return (
                        <ItemBigSliderComponent 
                            key={idx} 
                            item={elm}
                            hoveredElement={props.hoveredElement} 
                            index={idx}
                        />
                    )
                    
                })
            }
        </div>        
    )
}

export default BigSliderComponent;