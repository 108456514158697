import React from 'react';
import { withRouter } from 'react-router-dom';
import './AccountComponent.css';

function AccountComponent(props){
    return(
        <div className="wrapper-account">
            <div>
                <span className="settings_title">ACCOUNT</span>
            </div>
            <div>
                {
                    localStorage.getItem('authorizeToken') ?
                        <span className="settings_content_item">Logout to your account here.</span>
                    :
                        <span className="settings_content_item">Login to your account here.</span>
                }
            </div>
            <div>
                {
                    localStorage.getItem('authorizeToken') ? 
                        <button id={"2_0"} type="button" className="button-settings">
                            Log out
                        </button>
                    :
                        <button id={"2_0"} type="button" className="button-settings">
                            Log in
                        </button>
                }
            </div>
        </div>
    )
}

export default withRouter(AccountComponent);