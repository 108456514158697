import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router-dom';
import './LayoutComponent.css';
import BrowseHeader from '../Shared/BrowseHeader/BrowseComponent';
import GenresWrapperComponent from '../Genres/GenresWrapper/GenresWrapperComponent';
import FeaturedWrapperComponent from '../Featured/FeaturedWrapperComponent/FeaturedWrapperComponent';
import { env } from '../../config';
import SerieWrapperComponent from '../ItemDetail/Serie/SerieWrapperComponent';
import VideoWrapperComponent from '../ItemDetail/Video/VideoWrapperComponent';
import PlaylistWrapperComponent from '../ItemDetail/Playlist/PlaylistWrapperComponent';
import SearchWrapperComponent from '../Search/SearchWrapper/SearchWrapperComponent';
import ExploreWrapperComponent from '../Explore/ExploreWrapper/ExploreWrapperComponent';
import SettingsWrapperComponent from '../Settings/SettingsWrapper/SettingsWrapperComponent';

class LayoutComponent extends Component {

    constructor(props){
        super(props);
        this.state = {
            documentaries: [],
            loading: true,
            backgroundImage: null,
            filterBlur: null,
            titleImage : null
        }
    }

    changeBackground = (item) => {
        if(item && item.isFeatured){
            this.setState({
                backgroundImage: "url("+env.smartTvBucket+'images/'+item.featuredHeroWeb+") center center / cover"
            })
        }
        else {
            this.setState({
                backgroundImage: "url("+env.cloudfrontUrl+item.featuredHeroOTT+env.qualityBigSlider+") center center / cover",
                filterBlur: item.filterBlur ? item.filterBlur : null
            })
        }
    }

    changeTitleImage = (item) => {
        this.setState({
            titleImage: item.featuredTitleImage 
            ? env.cloudfrontUrl + item.featuredTitleImage + env.qualityTitleImage 
            : item.featuredTagline 
                ? env.cloudfrontUrl + item.defaultImage + env.qualityTitleImage
                : ""
        });   
    }

    goToItemDetail = (item) => {
        let keys = Object.keys(item)[0];
        switch(keys){
            case 'seriesId':
                this.props.history.push({
                    pathname: "/series/"+item.seriesId,
                    state: { data: item }
                });
                break;
            case 'videoId': 
                this.props.history.push({
                    pathname: "/video/"+item.videoId,
                    state: { data: item }
                });
                break;
            case 'playlistId':
                this.props.history.push({
                    pathname: "/playlist/"+item.playlistId,
                    state: { data: item }
                });
                break;        
            default:
                return;
        }  
    }

    toggleLoader = (value) => {
        this.setState({
            loading: value
        })
    }

    renderPageContent = (page) => {
        switch(page){
            case "featured":
                return (
                    <FeaturedWrapperComponent 
                        changeBackground={this.changeBackground} 
                        changeTitleImage={this.changeTitleImage}
                        titleImage={this.state.titleImage}
                        toggleLoader={this.toggleLoader}
                        loading={this.state.loading}
                        goToItemDetail={this.goToItemDetail}
                    />
                )
            case "genres": 
                return (
                    <GenresWrapperComponent 
                        changeBackground={this.changeBackground}
                        toggleLoader={this.toggleLoader}
                        loading={this.state.loading}
                        goToItemDetail={this.goToItemDetail}
                    />
                )
            case "series":
                return <SerieWrapperComponent 
                    changeBackground={this.changeBackground}
                    toggleLoader={this.toggleLoader}
                    loading={this.state.loading}
                    goToItemDetail={this.goToItemDetail}
                />
            case "videos": 
                return <VideoWrapperComponent 
                    changeBackground={this.changeBackground}
                    toggleLoader={this.toggleLoader}
                    loading={this.state.loading}    
                    goToItemDetail={this.goToItemDetail}
                />
            case "playlist": 
                return <PlaylistWrapperComponent 
                    changeBackground={this.changeBackground}
                    toggleLoader={this.toggleLoader}
                    loading={this.state.loading}    
                    goToItemDetail={this.goToItemDetail}
                /> 
            case "search":
                return <SearchWrapperComponent 
                    changeBackground={this.changeBackground}
                    toggleLoader={this.toggleLoader}
                    loading={this.state.loading}    
                    goToItemDetail={this.goToItemDetail}   
                />
            case "explore":
                return <ExploreWrapperComponent 
                    changeBackground={this.changeBackground}
                    toggleLoader={this.toggleLoader}
                    loading={this.state.loading}    
                    goToItemDetail={this.goToItemDetail}   
                />
            case "settings":
                return <SettingsWrapperComponent 
                    changeBackground={this.changeBackground}
                    toggleLoader={this.toggleLoader}
                    loading={this.state.loading}
                />
            default: 
                return;
        }
    }

    render(){
        return (
            <Fragment>
                {
                    !this.state.loading ? 
                        <div 
                            className="main-wrapper-body"
                            style={{ 
                                background: this.state.backgroundImage ? this.state.backgroundImage : "", 
                                filter: this.state.filterBlur ? 'blur(20px)' : ""
                            }}
                        >
                            {
                                this.props.location.pathname === "/featured" ||
                                this.props.location.pathname.indexOf('/video') === -1 ||
                                this.props.location.pathname.indexOf('/series') === -1||
                                this.props.location.pathname.indexOf('/playlist') === -1 ? 
                                    <div className={"overlay bg-overlay-light"}></div> 
                                : 
                                    this.props.location.pathname !== "/genres" && 
                                    this.props.location.pathname !== "/search" &&
                                    this.props.location.pathname !== "/explore" &&
                                    this.props.location.pathname !== "/settings" &&
                                    this.props.location.pathname.indexOf('/video') !== -1 &&
                                    this.props.location.pathname.indexOf('/series') !== -1 &&
                                    this.props.location.pathname.indexOf('/playlist') !== -1 ?
                                        <div className={"overlay bg-overlay-dark"}></div>
                                    : null 
                            } 
                        </div> 
                    : 
                        null
                } 
                <div className={
                    this.state.loading || 
                    this.props.location.pathname === "/search" ||
                    this.props.location.pathname === "/genres" ||
                    this.props.location.pathname === "/settings" ||
                    this.props.location.pathname.indexOf('/video') !== -1 ||
                    this.props.location.pathname.indexOf('/series') !== -1||
                    this.props.location.pathname.indexOf('/playlist') !== -1
                    ? "main-layout height-loader"
                    : "main-layout normal-height"
                }>
                    {this.state.loading ? null : <BrowseHeader />} 
                    {this.renderPageContent(this.props.page)}
                </div> 
            </Fragment>
        )
    }
    
}
export default withRouter(LayoutComponent);