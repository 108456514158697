import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import GeneralComponent from '../GeneralComponent';
import Player from './VideoPlayerConfig';
import axios from 'axios';
import { env, urlApiCalls, urlRoutes } from '../../config';
import ActivationService from '../../services/ActivationService';
import LoaderComponent from '../Shared/Loader/LoaderComponent';
import Remote from '../../services/RemoteService';

class VideoPlayerComponent extends GeneralComponent{

    constructor(props) {
        super(props);
        this.state = {
            video: null,
            isVideoReady: false,
            playing: false,
            pip: false,
            secondsLapsed: 0,
            loading : true
        }
        this.videoId = this.props.location.state.data.videoId;
        this._isMount = false;
        this.player = React.createRef();
    }
    
    componentDidMount() {
        this.getVideoInfo(this.videoId);
        // If not video is loaded go to featured
        if (typeof this.props.location.state === 'undefined') {
            this.props.history.push(urlRoutes.featured);
        }
        this._isMount = true;
        Remote.registerRemote(this); // Register remote keys
    }
    
    componentWillUnmount () {
        this._isMount = false;
        Remote.unregisterRemote(); // Remove key listeners
    }    

    
    /**
     * Method to call the HTTP request to finish the video
     */
    finishVideo = () => {
        let data = {
            userId: localStorage.getItem('authorizeToken'),
            itemId: this.videoId
        };

        axios.post(env.apiUrl+urlApiCalls.resumeOnComplete, data)
        .then(response => {})
        .catch(error => {
            if (error.response.status === 401){
                ActivationService.reAuthenticate()
                    .then(() => {
                        this.finishVideo();
                    })
                    .catch(err => {
                        console.log('reAuthenticate error:', err); 
                    });
            }
        })    
    }

    /**
     * Method to update the current video time (Called each 10 seconds)
     * @param :: playedSeconds --> Seconds reproduced in the video
     */
    updateVideoProgress = () => {
        let videoDuration = parseInt(this.player.current.getDuration());
        let playedSeconds = parseInt(this.player.current.getCurrentTime());
        let data = {
            customerToken: localStorage.getItem('authorizeToken'),
            lastPlayTime: playedSeconds.toString(),
            totalPlayTime: videoDuration.toString(),
            videoId: this.videoId.toString()
        }
        let form_data = new FormData();

        for ( var key in data ) {
            form_data.append(key, data[key]);
        }

        axios.post(env.apiUrl+urlApiCalls.resume, form_data, {
            headers: {
                deviceToken: localStorage.getItem('deviceToken')
            }
        })
        .then(response => {
            //console.log("Response", response);
        })
        .catch(error => {
            if (error.response.status === 401){
                ActivationService.reAuthenticate()
                    .then(() => {
                        this.updateVideoProgress();
                    })
                    .catch(err => {
                        console.log('reAuthenticate error:', err); 
                    });
            }
        })
    }

    /**
     * Method to handle the progress each second elapsed
     * @param :: event --> Native event from onProgress function on video player
     */
    handleProgress = (event) => {
        let playedSeconds = parseInt(this.player.current.getCurrentTime());
        let videoDuration = parseInt(this.player.current.getDuration());
        const { previewMode } = this.state.video;
        
        if(localStorage.getItem('authorizeToken')){
            this.setState({
                secondsLapsed: playedSeconds
            });
            // Each ten seconds the update will be executed.
            if (
                playedSeconds !== 0 
                && ((playedSeconds % 10) === 0)
                && playedSeconds < (videoDuration - 9)
            ){
                this.updateVideoProgress();
            }
            else if ( playedSeconds >= (videoDuration - 9) ){
                this.onEnded();    
            }      
        }
        else { // The user is able to see just the video preview
            //5 minutes is the maximun time of preview, however videos with previewMode full  won't get stopped
            if(playedSeconds >= 300 && previewMode !== 'full'){
                this.handlePause();
                this.props.history.push(urlRoutes.deviceSignUp, {video: this.state.video, url: '/videoplayer'});
            }
        }
    }

    /**
     * Method to get the whole info from the video
     */
    getVideoInfo = (video) => {
        let headers = {}

        if(localStorage.getItem('authorizeToken')){
            headers.authorization = localStorage.getItem('authorizeToken');
            headers.deviceToken = localStorage.getItem('deviceToken');
        }
        else {
            headers.browseToken = localStorage.getItem('browseToken');  
        }
        
        axios.get(env.apiUrl + urlApiCalls.videos + this.videoId + '/', { headers })
        .then(response => {
            if(response.status === 200){
                this._isMount && this.setState({
                    video: response.data.responseData,
                    loading: false
                });
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                ActivationService.reAuthenticate()
                    .then(() => {
                        this.getVideoInfo(video);
                    })
                    .catch(err => {
                        console.log('reAuthenticate error:', err); 
                    });
            }
        });
    }

    /**
     * Method to handle the video play
     */
    handlePlay = () => {
        this.setState({ playing: true });
        this.player.current.play();
    }

    /**
     * Method to handle the video pause
     */
    handlePause = () => {
        this.setState({ playing: false });
        this.player.current.pause();
    }
    
    /**
     * Method to handle the video play or pause
     */
    handlePlayPause = () => {
        if(!this.state.playing){
            this.handlePlay();
        }else{
            this.handlePause();
        }
    }
    
    /**
     * Method to handle the video stop
     */
    handleStop = () => {
        this.setState({ playing: false });
        this.player.current.pause();
        this.player.current.currentTime = 0;
    }

    /**
     * Method to seek the video 5 seconds by 5 seconds
     */
    handleFastForwardVideo = () => {
        let duration = parseInt(this.player.current.getDuration());
        let currentTime = parseInt(this.player.current.getCurrentTime());
        let seekTo =  parseInt(currentTime + 5);
        
        this.player.current.seekTo(seekTo);
        
        if(seekTo > duration){
            this.player.current.seekTo(duration);
            this.finishVideo();
        }
        else if(seekTo < 0){
            this.player.current.seekTo(0);
        }
        else {
            this.player.current.seekTo(seekTo);
            // We can update the progress of the video
            if((seekTo % 10) === 0){
                this.updateVideoProgress();
            }
        }
    }
    
    /**
     * Method to rewind the video 5 seconds by 5 seconds
     */
    handleRewind = () => {
        let currentTime = parseInt(this.player.current.getCurrentTime());
        let rewindTo =  parseInt(currentTime - 5);
        
        // We are not able to rewind less than 0 seconds
        if(rewindTo < 0){
            this.player.current.seekTo(0);
        }
        else {
            this.player.current.seekTo(rewindTo);
            // We can to update the progress of the video
            if((rewindTo % 10) === 0){
                this.updateVideoProgress();    
            }
        }    
    }    
    
    /**
     * Update variables when video is playing
     */
    onPlaying = () => {
        this.setState({
            playing: true
        });
    }

    /*
     * Actions executed once the video ends
     */    
    onEnded = () => {

        if(!localStorage.getItem('authorizeToken')){
            return;
        }

        let data = {
            customerToken: localStorage.getItem('authorizeToken'),
            itemId: this.state.video.videoId
        };

        axios.post(env.apiUrl+urlApiCalls.resumeOnComplete, data)
        .then(response => {})
        .catch(error => {
            if (error.response.status === 401){
                ActivationService.reAuthenticate()
                    .then(() => {
                        this.finishVideo();
                    })
                    .catch(err => {
                        console.log('reAuthenticate error:', err); 
                    });
            }
        });
        
        this.props.history.goBack();
    }
    
    render(){
        return (
            <Fragment>
                {
                    ( this.state.loading || !this.state.video ) ?
                        <LoaderComponent />
                    :
                        <Player 
                            ref={this.player}
                            id={"video_player"}
                            url={this.state.video.jwp_video_url}
                            is4k={this.state.video.is_4k}
                            dashurl = {this.state.video.dash_video_url}
                            captions={this.state.video.jwp_captions}
                            width='100%'
                            height='100%'
                            start={this.state.video.lastPlayTime || 0}
                            onProgress={this.handleProgress}     
                            onEnded ={this.onEnded}
                            onPlaying ={this.onPlaying}
                        />
                }
            </Fragment>
        );
    }
    
}

export default withRouter(VideoPlayerComponent);