import React from 'react'
import './DotComponent.css';

/**
 * Dot Component
 * @param id 
 * @param active: true or false
 * @param dotClick : method when clicks on it 
 */

function DotComponent(props){
  return (
    <div id={"1_"+props.index} className={props.active ? 'dot active-dot' : 'dot'}></div>
  )
}

export default DotComponent;