import React from 'react';
import { withRouter } from 'react-router-dom';
import './SearchFormComponent.css';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CarouselComponent from '../../../Shared/Carousel/CarouselComponent';
//import { env } from '../../../../config';

function SearchFormComponent(props) {
    return (
        <div className="wrapper-form-container">
            <div className="form-container">
                <div>
                    <input 
                        className="input-style" 
                        placeholder="Search"
                        onChange={(ev) => props.setFilter(ev.target.value)} 
                        onKeyDown={props.onSearch}
                        id={props.inputId}
                    />
                </div>
            </div>
            {
                props.filtered ? 
                    <div className="wrapper-search-carousel" style={{alignSelf: 'flex-start'}}>
                        {
                            props.data && props.data.length !== 0 ?
                                <CarouselComponent 
                                    id_control={2}
                                    items={props.data}
                                    mainTitle={"Search"}
                                    goToItemDetail={props.goToItemDetail}
                                />
                            : null
                        }
                    </div>
                : null
            }
            {
                !props.data && <h2 className="initial-search-title">No Results Found.</h2>
            }
            {!props.filtered && props.data && <h2 className="initial-search-title">Search for your favorite series, playlists, videos and episodes.</h2>}
        </div>
    )
}

export default withRouter(SearchFormComponent);