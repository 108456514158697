import React, { Fragment } from 'react';

function ImageItemCarouselComponent(props){
    return (
        <Fragment>
            
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{marginBottom: 30}}>
                    <img 
                        id={"title-image-slider_"+props.id}
                        width={"382"}
                        height={"215"}
                        draggable={"false"}
                        alt={"Small slider"}
                        src={props.titleImage}
                        style={{marginRight: 4000}}
                    />
                </div>
                <div>
                    <span className="featured-tagline">{ props.featuredTagline }</span>
                </div>
            </div>
        </Fragment>
    )
}

export default ImageItemCarouselComponent;