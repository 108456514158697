import React, { Fragment } from 'react';
import './ItemDetailComponent.css';

function ItemDetailComponent(props){
    return(
        <Fragment>
            <div className="container-detail">
                <div>
                    <span className="item-detail-title">{props.item ? props.item.title : null}</span>
                </div>
                <div>
                    <span className="item-detail-duration">
                        {
                            props.item ? 
                                props.item.episodesCount ?
                                    props.item.episodesCount
                                : 
                                    props.item.duration ?
                                        props.item.duration
                                    :
                                        props.item.playlistCount ?
                                            props.item.playlistCount     
                                        : null
                            : null
                        }
                    </span>    
                </div>
                <div className="wrapper-item-description">
                    <span className="item-detail-description">{props.item ? props.item.shortDescription : null}</span>    
                </div>
            </div>
        </Fragment>
    )
}

export default ItemDetailComponent;