import React, { Fragment } from 'react';
import './CarouselComponent.css';
import ItemCarouselComponent from '../../Shared/ItemCarousel/ItemCarouselComponent';
import { env } from '../../../config';

function CarouselComponent(props){
    return(
        <Fragment>
            {
                props.items.length !== 0 ? 
                    <div className={props.page ? null : "container-carousel"}>
                        {
                            props.mainTitle ? 
                                <span className="documentarie-title">{props.mainTitle}</span>
                            : null
                        }
                        <div id={"controls_"+props.id_control} className="wrapper-carousel" style={{width: 350*props.items.length}}>
                            {
                                props.items.map((element, idx) => {
                                    return (
                                        <ItemCarouselComponent
                                            id_control={props.id_control} 
                                            key={idx}
                                            index={idx}
                                            item={element} 
                                            backgroundImage={
                                                env.cloudfrontUrl+element.defaultImage+env.qualityParams
                                            }
                                            goToItemDetail={props.goToItemDetail}
                                            getItemDetail={props.getItemDetail}
                                        />
                                    )
                                }) 
                            }
                        </div>
                    </div>
                : null
            }
        </Fragment>
    )
}

export default CarouselComponent;