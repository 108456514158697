import React, { Fragment } from 'react';
import LayoutComponent from '../../Layout/LayoutComponent';

function PlaylistComponent(props){
    return(
        <Fragment>
            <LayoutComponent page={"playlist"} item={props.location.state.data}/>
        </Fragment>
        
    )
}

export default PlaylistComponent;