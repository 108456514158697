import React from 'react';
import { withRouter } from 'react-router-dom';
import GeneralComponent from '../GeneralComponent';
import './ExitScreenComponent.css';
import { keyCode } from '../../config';

class ExitScreenComponent extends GeneralComponent {

    componentDidMount() {
        this.recognizeKeyDown();
        this.idTimeout = setTimeout(()=>{
            this.Controls.moveRight("exit-screen", "active-button", "active-button");
        }, 10)
    }

    componentWillUnmount(){
        clearTimeout(this.idTimeout);
        this.unmountListener();
    }

    useControls = (keyPressed) => {
        if (this.onBackButton(keyPressed, 'GoBack')) {
            return;
        }
        
        switch (keyPressed) {
            case keyCode.left:
                this.Controls.moveLeft("exit-screen", "active-button", "active-button");
                break;
            case keyCode.right:
                this.Controls.moveRight("exit-screen", "active-button", "active-button");
                break;
            case keyCode.back:
            case keyCode.backAlt:
                this.props.history.goBack();
                break;
            case keyCode.enter:
                if(this.Controls.getCurrentElement() === 1){  
                    this.props.history.goBack();
                } else {
                    try {
                        console.log('Exit App');
                        window.close();
                    } catch (e) {
                        console.error("Failed to close application", e)
                    }
                }
                break;
            default:
                return;
        }
    }

    render() {
        return (
            <div className="wrapper-exit">
                <div className="wrapper-title">
                    <span className="title-style">Are you sure you want to exit the app?</span>
                </div>
                <div id="controls_0" className="wrapper-buttons">
                    <div>
                        <button id="0_0" className="button-style title-style">Exit</button>
                    </div>
                    <div>
                        <button id="0_1" className="button-style title-style">Cancel</button>
                    </div>
                </div>
            </div>
        )
    }

}

export default withRouter(ExitScreenComponent);