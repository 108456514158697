import { keyCode } from '../../config';

export class KeyboardRemote {

    /**
     * Register keymap for generic remote devices
     */   
    static registerKeyCodes(){
        // TODO Extra key codes
    }
    
    /**
     * Unegister keymap for generic remote devices
     */  
    static unregisterKeyCodes(){
    }
        
    /**
     * Call events from component once a key is pressed
     * @param int keyPressed
     * @param React.Ref component
     */        
    static useControls = (keyPressed, component) => {
        switch(keyPressed){
            case keyCode.play:
                component.handlePlay();
                break;
            case keyCode.pause:
                component.handlePause();
                break;
            case keyCode.stop: 
                component.handleStop();
                break;
            case keyCode.back:
            case keyCode.backAlt:
                component.props.history.goBack();
                break;
            case keyCode.fastForward:
            case keyCode.right:
                component.handleFastForwardVideo();
                break;
            case keyCode.rewind:
                component.handleRewind();
                break;
            default:
                return;
        }
    }
}

export default KeyboardRemote;
