/**
 * Component to handle the keyboard to move in the app
 */

export class ControlService {
    activeElement = -1;
    activeSlider = -1;
    lastSliderActive = 0;
    lastMenuTab = 0;
    lastPixelsMoved = 0;
    offsetSliders = 70;
    itemsPerRow = 0;

    plusSliderActive = () => {
        let sliderCount = document.getElementById('controls_1').childElementCount - 1;
        if( sliderCount === this.lastSliderActive){
            this.lastSliderActive = 0;
        }
        else {
            this.lastSliderActive += 1;
        }
    }

    substractSliderActive = () => {
        if(this.lastSliderActive === 0){
            this.lastSliderActive = document.getElementById('controls_1').childElementCount - 1;
        }
        else {
            this.lastSliderActive -= 1;
        }

    }

    initGrid = (itemsPerRow) => {
        this.itemsPerRow = itemsPerRow;
    }

    /**
     * Increase active element inside a slider
     */
    plusCurrentElement = () => {
        let numberOfElements = null;
        if(this.getCurrentControl() === -1){
            numberOfElements = document.getElementById('controls_0').childElementCount;
        }
        else {
            numberOfElements = document.getElementById('controls_'+this.getCurrentControl().toString()).childElementCount;
        }

        if(this.activeElement === (numberOfElements - 1)){ // Is the last element in the array
            return;
        }

        if(this.activeElement === -1) {
            this.activeElement = 0;
        }
        else { // Any element in the middle of the panel
            this.activeElement += 1;
        }
    }

    /**
    * Decrease the active element of the active slider
    */
    substractCurrentElement = () => {
        if(this.activeElement === 0){ // Is the last element in sub array
            return;
        }
        else if(this.activeElement === -1){
            this.activeElement = 0;
        }
        else {
            this.activeElement -= 1;
        }
    }

    /**
     * Increse the active slider
     */
    plusCurrentControl = (page) => {
        let numberOfElements = null;
        if(this.getCurrentControl() === -1){
            numberOfElements = document.getElementById('controls_0').childElementCount;
        }
        else {
            let parentElement = document.getElementsByClassName('main-wrapper');

            if(parentElement){
                if(!page){
                    let childrenElement = parentElement[0].getElementsByClassName('wrapper-explore-carousels');
                    if(childrenElement.length !== 0){
                        numberOfElements = childrenElement[0].childElementCount;
                    }
                    else {
                        numberOfElements = parentElement.childElementCount;
                    }
                }
            }
        }

        if(this.activeSlider === numberOfElements+1){ // Is the last element in sub array
            return;
        }
        if(this.activeSlider === -1){
            this.activeSlider = 0;
        }
        else {
            this.activeSlider += 1;
        }
    }

    /**
     * Decrease the active slider
     */
    substractCurrentControl = () => {
        if(this.activeSlider === 0){ // Is the last element in sub array
            return;
        }
        else if(this.activeSlider === -1){
            this.activeSlider = 0;
        }
        else {
            this.activeSlider -= 1;
        }
    }

    getItemsPerRow = () => {
        return this.itemsPerRow;
    }
    /***
     * Get current active element
     */
    getCurrentElement = () => {
        return this.activeElement;
    }

    /**
     * Get currenct active slider
     */
    getCurrentControl = () => {
        return this.activeSlider;
    }

    /**
     * Reset the active element
     */
    resetElement= () => {
        this.activeElement = -1;
    }

    /**
    * Reset the active slider
    */
    resetSlider = () => {
        this.activeSlider = -1;
    }

    getLastElementActive = () => {
        return this.lastSliderActive;
    }

    /**
     * Scroll from up to down and viceverse
     */
    scrollToActiveSlider = (page) => {
        let idElement = this.activeSlider.toString()+'_'+this.activeElement.toString();
        let elm = document.getElementById(idElement);
        const rect = elm.getBoundingClientRect();

        if(page === "explore"){
            if(this.activeSlider === 2){
                window.scrollTo(0, parseInt(rect.top + window.scrollY - 60));
            }
            else {
                window.scrollTo(0, parseInt(rect.top + window.scrollY - 250));
            }
        }
        else {
            if (this.activeSlider > 2) {
                window.scrollTo(0, parseInt(rect.top + window.scrollY - 210));
            }
            else {
                window.scrollTo(0, parseInt(rect.top + window.scrollY - 70));
            }
        }
    }

    offsetRightCarousel = (page, cssToAdd, cssToRemove = cssToAdd) => {
        let countElements = document.getElementById('controls_'+this.getCurrentControl().toString()).childElementCount;

        if((countElements-1) !== this.getCurrentElement()){
            this.moveSimpleForward(page, cssToAdd, cssToRemove);
            this.scrollRightCarousel("controls_1");
        }
    }

    offsetLeftCarousel = (page, cssToAdd, cssToRemove) => {
        if(this.getCurrentElement() !== 0){
            this.moveSimpleBackwards(page, cssToAdd, cssToRemove);
            this.scrollLeftCarousel("controls_1");
        }
    }

    /**
     * Method to move the items inside the carousel from left to right
     */
    scrollRightCarousel = () => {
        let idElement = "controls_"+this.activeSlider.toString();
        document.getElementById(idElement).style.position = 'relative';
        document.getElementById(idElement).style.right = document.getElementById(idElement).style.right ?
        (parseInt(document.getElementById(idElement).style.right.split('px')[0], 10) + 360).toString()+'px' : "360px";
    }

    scrollLeftCarousel = () => {
        let idElement = "controls_"+this.activeSlider.toString();
        document.getElementById(idElement).style.position = 'relative';
        document.getElementById(idElement).style.right = document.getElementById(idElement).style.left ?
        (parseInt(document.getElementById(idElement).style.left.split('px')[0], 10) - 360).toString()+'px'
        : (parseInt(document.getElementById(idElement).style.right.split('px')[0], 10) - 360).toString()+'px'
    }

    scrollRightBigSlider = (classToFind) => {
        let idElement = classToFind;
        document.getElementById(idElement).style.position = 'relative';
        document.getElementById(idElement).style.right = this.lastPixelsMoved !== 0 ?
        this.lastPixelsMoved.toString()+'px' : "0px";
    }

    scrollLeftBigSlider = (classToFind) => {
        let idElement = classToFind;
        document.getElementById(idElement).style.position = 'relative';
        document.getElementById(idElement).style.right = this.lastPixelsMoved !== 0 ? this.lastPixelsMoved.toString()+'px' : "0px";
    }

    resetHorizontalScroll = () => {
        let idElement = "controls_"+this.activeSlider.toString();
        document.getElementById(idElement).style.right = 0;
    }

    moveSimpleForward = (page, cssToAdd, cssToRemove) => {
        if(this.getCurrentControl() === -1 && this.getCurrentElement() === -1){
            document.getElementById((this.activeSlider+1).toString()+'_'+(this.activeElement+1).toString()).classList.add(cssToAdd);
            this.plusCurrentElement();
            this.plusCurrentControl();
        }
        else {
            let parent = document.getElementById('controls_'+this.getCurrentControl()).childElementCount;
            if((parent-1) === this.activeElement){
                return;
            }
            else {
                document.getElementById(this.activeSlider.toString()+'_'+(this.activeElement+1).toString()).classList.add(cssToAdd);
                document.getElementById(this.activeSlider.toString()+'_'+this.activeElement.toString()).classList.remove(cssToRemove);
                this.plusCurrentElement();
            }
        }
    }

    moveSimpleBackwards = (page, cssToAdd, cssToRemove) => {
        if(this.getCurrentControl() === -1 && this.getCurrentElement() === -1){
            document.getElementById((this.activeSlider+1).toString()+'_'+(this.activeElement+1).toString()).classList.add(cssToAdd);
            this.plusCurrentElement();
            this.plusCurrentControl();
        }
        else {
            if(this.activeElement === 0){
                return;
            }
            else {
                document.getElementById(this.activeSlider.toString()+'_'+(this.activeElement-1).toString()).classList.add(cssToAdd);
                document.getElementById(this.activeSlider.toString()+'_'+this.activeElement.toString()).classList.remove(cssToRemove);
                this.substractCurrentElement();
            }
        }
    }

    scrollInnerDivCarousel = () => {
        let myElement = document.getElementById(this.activeSlider.toString()+'_'+(this.activeElement+this.itemsPerRow).toString());
        let topPos = myElement.offsetTop - 200;
        document.getElementById('carousel_menu').scrollTop = topPos;
    }

    scrollFromBottomToTop = () => {
        let myElement = document.getElementById(this.activeSlider.toString()+'_'+(this.activeElement-this.itemsPerRow).toString());
        let topPos = myElement.offsetTop - 200;
        document.getElementById('carousel_menu').scrollTop = topPos;
    }

    resetInnerDivCarousel = () => {
        document.getElementById('carousel_menu').scrollTop = 0;
    }

    /**
    * (User pressed key=40 --> Arrow Down
    * @param page: what page is going to use the function
    * @param cssToAdd : className to be added in new active element
    * @param cssToRemoveElm: className to be removed in last active element
    */
    moveDown = (page, cssToAdd = "", cssToRemove = cssToAdd) => {
        switch(page){
            case 'paywall':
                this.moveSimpleForward(page, cssToAdd, cssToRemove);
                break;
            case 'device-signin':
                this.moveSimpleForward(page, cssToAdd, cssToRemove);
                break;
            case 'featured':
                // Activating the tab in the header
                if(this.getCurrentControl() === -1 && this.getCurrentElement() === -1){
                    document.getElementById("0_0").classList.add(cssToAdd);
                    this.plusCurrentElement();
                    this.plusCurrentControl();
                }
                else if(this.getCurrentControl() === 0){ // Positioned in header
                    document.getElementById(this.activeSlider.toString()+'_'+this.activeElement.toString()).classList.remove(cssToRemove);
                    document.getElementById(this.activeSlider.toString()+'_0').children[0].classList.add("active-tab");
                    document.getElementById((this.activeSlider+1).toString()+'_'+this.lastSliderActive.toString()).classList.add("active-dot");
                    this.activeElement = this.lastSliderActive;
                    this.plusCurrentControl();
                }
                else if(this.getCurrentControl() === 1){
                    document.getElementById('watch-button-wrap').classList.remove(cssToRemove);
                    document.getElementById((this.activeSlider+1).toString()+'_0').classList.add(cssToAdd);
                    this.lastSliderActive = this.activeElement;
                    this.activeElement = 0;
                    this.plusCurrentControl();
                    this.scrollToActiveSlider();
                }
                else if(this.getCurrentControl() > 1 && document.getElementsByClassName('main-wrapper')[0].childElementCount !== this.activeSlider){
                    this.resetHorizontalScroll();
                    document.getElementById(this.activeSlider.toString()+'_'+this.activeElement.toString()).classList.remove(cssToRemove);
                    this.activeElement = 0;
                    document.getElementById((this.activeSlider+1).toString()+'_'+this.activeElement.toString()).classList.add(cssToAdd);
                    this.plusCurrentControl();
                    this.scrollToActiveSlider();
                }
                break;
            case 'explore':
                // Activating the tab in the header
                if(this.getCurrentControl() === -1 && this.getCurrentElement() === -1){
                    document.getElementById("0_1").classList.add(cssToAdd);
                    this.plusCurrentControl();
                    this.activeElement = 1;
                }
                else if(this.getCurrentControl() === 0){
                    document.getElementById(this.activeSlider.toString()+'_'+this.activeElement.toString()).classList.remove(cssToRemove);
                    document.getElementById(this.activeSlider.toString()+'_1').children[0].classList.add("active-tab");
                    document.getElementById((this.activeSlider+1).toString()+'_'+this.lastSliderActive.toString()).classList.add(cssToAdd);
                    this.plusCurrentControl();
                }
                else if(this.getCurrentControl() === 1){
                    document.getElementById(this.activeSlider.toString()+'_'+this.lastSliderActive.toString()).classList.remove(cssToRemove);
                    this.activeElement = 0;
                    document.getElementById((this.activeSlider+1).toString()+'_'+this.activeElement.toString()).classList.add(cssToAdd);
                    this.plusCurrentControl();
                    this.scrollToActiveSlider("explore");
                }
                else {
                    if(this.activeSlider !== document.getElementsByClassName('wrapper-explore-carousels')[0].childElementCount + 1){
                        this.resetHorizontalScroll();
                        document.getElementById(this.activeSlider.toString()+'_'+this.activeElement.toString()).classList.remove(cssToRemove);
                        this.activeElement = 0;
                        document.getElementById((this.activeSlider+1).toString()+'_'+this.activeElement.toString()).classList.add(cssToAdd);
                        this.plusCurrentControl();
                        this.scrollToActiveSlider("explore");
                    }
                }
                break;
            case 'genres':
                if(this.getCurrentControl() === -1 && this.getCurrentElement() === -1){
                    document.getElementById("0_2").classList.add(cssToAdd);
                    this.activeSlider = 0;
                    this.activeElement = 2;
                }
                else if(this.getCurrentControl() === 0){
                    document.getElementById('0_2').children[0].classList.add("active-tab");
                    document.getElementById(this.activeSlider.toString()+'_'+this.activeElement.toString()).classList.remove(cssToRemove);
                    this.activeSlider = 1;
                    this.activeElement = 0;
                    document.getElementById(this.activeSlider.toString()+'_'+this.activeElement.toString()).classList.add(cssToAdd);
                }
                else if(this.getCurrentControl() === 1){
                    this.moveSimpleForward(page, cssToAdd, cssToRemove);
                    this.lastMenuTab = this.activeElement;
                    this.lastSliderActive = 0;
                    this.resetInnerDivCarousel();
                }
                else {
                    let countParentElements = document.getElementById('carousel_menu').childElementCount;
                    if(this.lastSliderActive + this.itemsPerRow < countParentElements){
                        this.scrollInnerDivCarousel();
                        document.getElementById(this.activeSlider.toString()+'_'+(this.activeElement+this.itemsPerRow).toString()).classList.add(cssToAdd);
                        document.getElementById(this.activeSlider.toString()+'_'+this.activeElement.toString()).classList.remove(cssToRemove);
                        this.activeElement += this.itemsPerRow;
                        this.lastSliderActive = this.activeElement;
                    }
                }
                break;
            case 'video':
                if(this.getCurrentControl() === -1 && this.getCurrentElement() === -1){
                    document.getElementById("controls_1").classList.add(cssToAdd);
                    this.activeSlider = 1;
                    this.activeElement = 0;
                }
                else if(this.getCurrentControl() === 0){
                    document.getElementById(this.activeSlider.toString()+'_'+this.activeElement.toString()).classList.remove(cssToRemove);
                    document.getElementById("controls_1").classList.add(cssToAdd);
                    this.activeSlider = 1;
                    this.activeElement = 0;
                }
                else if(this.getCurrentControl() >= 1 && this.getCurrentControl() < 3){
                    this.resetHorizontalScroll();
                    document.getElementById("controls_"+this.activeSlider.toString()).classList.remove(cssToRemove);
                    document.getElementById((this.activeSlider+1).toString()+'_'+this.activeElement.toString()).classList.add(cssToAdd);
                    if(this.getCurrentControl() > 1){
                        document.getElementById(this.activeSlider.toString()+'_'+this.activeElement.toString()).classList.remove(cssToAdd);
                    }
                    this.activeElement = 0;
                    this.activeSlider += 1;
                    this.scrollToActiveSlider();
                }
                break;
            case 'search':
                if(this.getCurrentControl() === -1 && this.getCurrentElement() === -1){
                    document.getElementById("0_3").classList.add('hover-background');
                    this.activeSlider = 0;
                    this.activeElement = 3;
                }
                break;
            case 'tryfree':
                if(this.getCurrentControl() === -1 || this.getCurrentControl() === 0){
                    document.getElementById("0_4").classList.add('hover-background');
                    this.activeSlider = 0;
                    this.activeElement = 4;
                }
                break;
            case 'settings':
                if(this.getCurrentControl() === -1 || this.getCurrentControl() === 0){
                    document.getElementById("0_4").classList.add('hover-background');
                    this.activeSlider = 0;
                    this.activeElement = 4;
                }
                break;
            default:
                return;
        }
    }

    /**
    * (User pressed key=38 --> Arrow Up
    * @param page: what page is going to use the function
    * @param cssToAdd : className to be added in new active element
    * @param cssToRemoveElm: className to be removed in last active element
    */
    moveUp = (page, cssToAdd = "", cssToRemove = cssToAdd) => {
        switch(page){
            case 'paywall':
                this.moveSimpleBackwards(page, cssToAdd, cssToRemove);
                break;
            case 'device-signin':
                this.moveSimpleBackwards(page, cssToAdd, cssToRemove);
                break;
            case 'featured':
                if(this.getCurrentControl() === -1){
                    this.moveSimpleBackwards(page, cssToAdd, cssToRemove);
                }
                else if(this.getCurrentControl() === 1){
                    document.getElementsByClassName('active-tab')[0].classList.remove('active-tab');
                    document.getElementById((this.activeSlider-1).toString()+'_0').classList.add(cssToAdd);
                    document.getElementById("watch-button-wrap").classList.remove(cssToRemove);
                    this.lastSliderActive = this.activeElement;
                    this.activeElement = 0;
                    this.substractCurrentControl();
                }
                else if(this.getCurrentControl() === 2){
                    document.getElementById("watch-button-wrap").classList.add(cssToAdd);
                    document.getElementById(this.activeSlider.toString()+'_'+this.activeElement.toString()).classList.remove(cssToRemove);
                    this.resetHorizontalScroll();
                    this.activeElement = this.lastSliderActive;
                    this.substractCurrentControl();
                    window.scrollTo(0, 0);
                }
                else {
                    this.resetHorizontalScroll();
                    document.getElementById(this.activeSlider.toString()+'_'+this.activeElement.toString()).classList.remove(cssToRemove);
                    this.activeElement = 0;
                    document.getElementById((this.activeSlider-1).toString()+'_'+this.activeElement.toString()).classList.add(cssToAdd);
                    this.substractCurrentControl();
                    this.scrollToActiveSlider("featured");
                }
                break;
            case 'explore':
                if(this.getCurrentControl() === -1){
                    document.getElementById("0_1").childNodes[0].classList.add(cssToAdd);
                    this.plusCurrentControl();
                    this.activeElement = 1;
                }
                else if(this.getCurrentControl() === 1){
                    document.getElementById((this.activeSlider-1).toString()+'_1').classList.add(cssToAdd);
                    document.getElementById(this.activeSlider.toString()+'_'+this.lastSliderActive.toString()).classList.remove(cssToRemove);
                    document.getElementsByClassName('active-tab')[0].classList.remove('active-tab');
                    this.substractCurrentControl();
                    this.activeElement = 1;
                }
                else{
                    this.resetHorizontalScroll();
                    document.getElementById(this.activeSlider.toString()+'_'+this.activeElement.toString()).classList.remove(cssToRemove);
                    this.activeElement = 0;

                    if(this.getCurrentControl() - 1 === 1){
                        document.getElementById((this.activeSlider-1).toString()+'_'+this.lastSliderActive.toString()).classList.add(cssToAdd);
                    }
                    else {
                        document.getElementById((this.activeSlider-1).toString()+'_'+this.activeElement.toString()).classList.add(cssToAdd);
                    }
                    this.substractCurrentControl();
                    this.scrollToActiveSlider("explore");
                }
                break;
            case 'genres':
                if(this.getCurrentControl() === 1){
                    this.lastSliderActive = 0;
                    this.resetInnerDivCarousel();
                    if(this.getCurrentElement() === 0){
                        document.getElementsByClassName('active-tab')[0].classList.remove('active-tab');
                        document.getElementById((this.activeSlider-1).toString()+'_2').classList.add(cssToAdd);
                        document.getElementById(this.activeSlider.toString()+'_'+this.activeElement.toString()).classList.remove('active-side-tab');
                        this.activeElement = 2;
                        this.activeSlider = 0;
                    }
                    else {
                        this.moveSimpleBackwards(page, cssToAdd, cssToRemove);
                        this.lastMenuTab = this.activeElement;
                    }
                }
                else if(this.getCurrentControl() === 2){
                    if (this.lastSliderActive - this.itemsPerRow >= 0){
                        this.scrollFromBottomToTop();
                        document.getElementById(this.activeSlider.toString()+'_'+(this.activeElement-this.itemsPerRow).toString()).classList.add(cssToAdd);
                        document.getElementById(this.activeSlider.toString()+'_'+this.activeElement.toString()).classList.remove(cssToRemove);
                        this.activeElement -= this.itemsPerRow;
                        this.lastSliderActive = this.activeElement;
                    }
                }
                break;
            case "video":
                if(this.getCurrentControl() === 1){
                    document.getElementById('controls_1').classList.remove(cssToRemove);
                    document.getElementById('0_0').classList.add(cssToAdd);
                    this.activeElement = 0;
                    this.activeSlider = 0;
                }
                else if(this.getCurrentControl() === 2){
                    this.resetHorizontalScroll();
                    document.getElementById('controls_1').classList.add(cssToAdd);
                    document.getElementById('controls_2').classList.remove(cssToRemove);
                    document.getElementById(this.activeSlider.toString()+'_'+this.activeElement.toString()).classList.remove(cssToRemove);
                    this.activeSlider = 1;
                    this.activeElement = 0;
                    window.scrollTo(0,0);
                }
                else if(this.getCurrentControl() === 3){
                    this.resetHorizontalScroll();
                    document.getElementById("controls_3").classList.remove(cssToRemove);
                    document.getElementById(this.activeSlider.toString()+'_'+this.activeElement.toString()).classList.remove(cssToRemove);
                    document.getElementById((this.activeSlider-1).toString()+'_0').classList.add(cssToAdd);
                    this.activeSlider = 2;
                    this.activeElement = 0;
                    this.scrollToActiveSlider();
                }
                break;
            default:
                return;
        }
    }

    /**
    * (User pressed key=39 --> Arrow Right
    * @param page: what page is going to use the function
    * @param cssToAdd : className to be added in new active element
    * @param cssToRemoveElm: className to be removed in last active element
    */
    moveRight = (page, cssToAdd = "", cssToRemove = cssToAdd) => {
        switch(page){
            case 'exit-screen':
                this.moveSimpleForward(page, cssToAdd, cssToRemove);
                break;
            case 'featured':
                if(this.getCurrentControl() === 0){
                    this.moveSimpleForward(page, cssToAdd, cssToRemove);
                }
                else if(this.getCurrentControl() === 1){
                    if(this.activeElement === document.getElementById('image-title-container').childElementCount - 1){
                        this.lastPixelsMoved = 0;
                        this.lastSliderActive = 0;
                    }
                    else {
                        this.lastPixelsMoved += 4382;
                        this.plusSliderActive();
                    }
                    this.scrollRightBigSlider("image-title-container");
                    this.activeElement = this.lastSliderActive;
                }
                else if(this.getCurrentControl() > 1){
                    if(this.getCurrentElement() === 0){
                        this.moveSimpleForward(page, cssToAdd, cssToRemove);
                    }
                    else {
                        this.offsetRightCarousel(page, cssToAdd, cssToRemove);
                    }
                }
                break;
            case 'explore':
                if(this.getCurrentControl() === 0){
                    this.moveSimpleForward(page, cssToAdd, cssToRemove);
                }
                else if(this.getCurrentControl() === 1){
                    if(this.lastSliderActive+1 === document.getElementById('controls_1').childElementCount){
                        document.getElementById(this.activeSlider.toString()+'_0').classList.add(cssToAdd);
                        document.getElementById(this.activeSlider.toString()+'_'+this.lastSliderActive.toString()).classList.remove(cssToRemove);
                        this.lastPixelsMoved = 0;
                        this.lastSliderActive = 0;
                        this.activeElement = this.lastSliderActive;
                    }
                    else {
                        document.getElementById(this.activeSlider.toString()+'_'+(this.lastSliderActive+1).toString()).classList.add(cssToAdd);
                        document.getElementById(this.activeSlider.toString()+'_'+this.lastSliderActive.toString()).classList.remove(cssToRemove);
                        switch(this.lastSliderActive){
                            case 0:
                                this.lastPixelsMoved += 1070;
                                break;
                            case 1:
                                this.lastPixelsMoved += 1135;
                                break;
                            case 2:
                                this.lastPixelsMoved += 1145;
                                break;
                            case 3:
                                this.lastPixelsMoved += 1150;
                                break;
                            default:
                                this.lastPixelsMoved += 1135;
                        }
                        this.lastSliderActive += 1;
                        this.activeElement = this.lastSliderActive;
                    }
                    this.scrollRightBigSlider("controls_1");
                }
                else if(this.getCurrentControl() > 1){
                    if(this.getCurrentElement() === 0){
                        this.moveSimpleForward(page, cssToAdd, cssToRemove);
                    }
                    else {
                        this.offsetRightCarousel(page, cssToAdd, cssToRemove);
                    }
                }
                break;
            case 'genres':
                if(this.getCurrentControl() === -1 || this.getCurrentControl() === 0){
                    this.moveSimpleForward(page, cssToAdd, cssToRemove);
                }
                else if(this.getCurrentControl() === 1){
                    document.getElementById('2_'+this.lastSliderActive.toString()).classList.add(cssToAdd);
                    document.getElementById(this.activeSlider.toString()+'_'+this.activeElement.toString()).classList.remove(cssToRemove);
                    this.activeSlider = 2;
                    this.activeElement = this.lastSliderActive;
                }
                else {
                    let countParentElements = document.getElementById('carousel_menu').childElementCount;

                    if( (((this.lastSliderActive+1) - this.itemsPerRow) % this.itemsPerRow !== 0) && (this.lastSliderActive+1) < countParentElements){
                        document.getElementById('2_'+(this.lastSliderActive + 1).toString()).classList.add(cssToAdd);
                        document.getElementById('2_'+this.activeElement.toString()).classList.remove(cssToRemove);
                        this.lastSliderActive += 1;
                        this.activeElement = this.lastSliderActive;
                    }
                }
                break;
            case 'video':
                if(this.getCurrentControl() === -1 || this.getCurrentControl() === 0){
                    this.moveSimpleForward(page, cssToAdd, cssToRemove);
                }
                else if(this.getCurrentControl() === 2 || this.getCurrentControl() === 3){
                    if(this.getCurrentElement() === 0){
                        this.moveSimpleForward(page, cssToAdd, cssToRemove);
                    }
                    else {
                        this.offsetRightCarousel(page, cssToAdd, cssToRemove);
                    }
                }
                break;
            case 'playlist':
                if(this.getCurrentControl() === -1 || this.getCurrentControl() === 0){
                    this.moveSimpleForward(page, cssToAdd, cssToRemove);
                }
                else if(this.getCurrentControl() === 1 || this.getCurrentControl() === 2){
                    if(this.getCurrentElement() === 0){
                        this.moveSimpleForward(page, cssToAdd, cssToRemove);
                    }
                    else {
                        this.offsetRightCarousel(page, cssToAdd, cssToRemove);
                    }
                }
                break;
            default:
                return;
        }
    }

    /**
    * (User pressed key=37 --> Arrow Left
    * @param page: what page is going to use the function
    * @param cssToAdd : className to be added in new active element
    * @param cssToRemoveElm: className to be removed in last active element
    */
    moveLeft = (page, cssToAdd = "", cssToRemove = cssToAdd) => {
        switch(page){
            case 'exit-screen':
                this.moveSimpleBackwards(page, cssToAdd, cssToRemove);
                break;
            case 'featured':
                if(this.getCurrentControl() === 0){
                    this.moveSimpleBackwards(page, cssToAdd, cssToRemove);
                }
                if(this.getCurrentControl() === 1){
                    if(this.activeElement === 0){
                        this.lastPixelsMoved = (document.getElementById('image-title-container').childElementCount-1)*4382;
                        this.lastSliderActive = document.getElementById('image-title-container').childElementCount - 1;
                    }
                    else {
                        this.lastPixelsMoved -= 4382;
                        this.substractSliderActive();
                    }
                    this.scrollLeftBigSlider("image-title-container");
                    this.activeElement = this.lastSliderActive;
                }
                else if(this.getCurrentControl() > 1){
                    if(this.getCurrentElement() === 1){
                        this.moveSimpleBackwards(page, cssToAdd, cssToRemove);
                    }
                    else if(this.getCurrentElement() !== 0){
                        this.offsetLeftCarousel(page, cssToAdd, cssToRemove);
                    }
                }
                break;
            case 'explore':
                if(this.getCurrentControl() === 0){
                    this.moveSimpleBackwards(page, cssToAdd, cssToRemove);
                }
                else if(this.getCurrentControl() === 1){
                    document.getElementById( this.activeSlider.toString()+'_'+ ((document.getElementById("controls_1")).childElementCount - 1).toString()).classList.add(cssToAdd);
                    document.getElementById(this.activeSlider.toString()+'_0').classList.remove(cssToRemove);
                    if(this.lastSliderActive === 0){
                        this.lastPixelsMoved = 4475;
                        this.lastSliderActive = 4;
                    }
                    else if(this.lastSliderActive >= 1){
                        document.getElementById(this.activeSlider.toString()+'_'+(this.lastSliderActive-1).toString()).classList.add(cssToAdd);
                        document.getElementById(this.activeSlider.toString()+'_'+this.lastSliderActive.toString()).classList.remove(cssToRemove);
                        switch(this.lastSliderActive){
                            case 1:
                                this.lastPixelsMoved -= 1070;
                                break;
                            case 2:
                                this.lastPixelsMoved -= 1135;
                                break;
                            case 3:
                                this.lastPixelsMoved -= 1145;
                                break;
                            case 4:
                                this.lastPixelsMoved -= 1150;
                                break;
                            default:
                                this.lastPixelsMoved -= 1135;
                        }
                        this.lastSliderActive -= 1;
                    }
                    this.scrollLeftBigSlider("controls_1");
                }
                else {
                    if(this.getCurrentElement() === 1){
                        this.moveSimpleBackwards(page, cssToAdd, cssToRemove);
                    }
                    else if(this.getCurrentElement() !== 0){
                        this.offsetLeftCarousel(page, cssToAdd, cssToRemove);
                    }
                }
                break;
            case 'genres':
                if(this.getCurrentControl() === -1 || this.getCurrentControl() === 0){
                    this.moveSimpleBackwards(page, cssToAdd, cssToRemove);
                }
                else if(this.getCurrentControl() === 2){
                    if(this.lastSliderActive === 0 || this.lastSliderActive % this.itemsPerRow === 0){
                        document.getElementById((this.activeSlider-1).toString()+'_'+this.lastMenuTab.toString()).classList.add(cssToAdd);
                        document.getElementById((this.activeSlider-1).toString()+'_'+this.lastMenuTab.toString()).classList.remove('active-elm');
                        document.getElementById(this.activeSlider.toString()+'_'+this.activeElement.toString()).classList.remove(cssToRemove);
                        this.activeSlider -= 1;
                        this.activeElement = this.lastMenuTab;
                    }
                    else {
                        document.getElementById('2_'+(this.lastSliderActive-1).toString()).classList.add(cssToAdd);
                        document.getElementById('2_'+this.lastSliderActive.toString()).classList.remove(cssToRemove);
                        this.lastSliderActive -= 1;
                        this.activeElement = this.lastSliderActive;
                    }
                }
                break;
            case 'video':
                if(this.getCurrentControl() === -1 || this.getCurrentControl() === 0){
                    this.moveSimpleBackwards(page, cssToAdd, cssToRemove);
                }
                else if(this.getCurrentControl() === 2 || this.getCurrentControl() === 3){
                    if(this.getCurrentElement() === 1){
                        this.moveSimpleBackwards(page, cssToAdd, cssToRemove);
                    }
                    else if(this.getCurrentElement() !== 0){
                        this.offsetLeftCarousel(page, cssToAdd, cssToRemove);
                    }
                }
                break;
            case 'playlist':
                if(this.getCurrentControl() === -1 || this.getCurrentControl() === 0){
                    this.moveSimpleBackwards(page, cssToAdd, cssToRemove);
                }
                else if(this.getCurrentControl() === 1 || this.getCurrentControl() === 2){
                    if(this.getCurrentElement() === 1){
                        this.moveSimpleBackwards(page, cssToAdd, cssToRemove);
                    }
                    else if(this.getCurrentElement() !== 0){
                        this.offsetLeftCarousel(page, cssToAdd, cssToRemove);
                    }
                }
                break;
            default:
                return;
        }
    }

    /**
     * @param props : props coming from the parent element
     * @param urlToNavigate: URL to navigate (optional)
     */
    pressEnter = (props, urlToNavigate) => {
       props.history.push(urlToNavigate);
    }
}








