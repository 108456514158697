import React, { Fragment } from 'react';
import LayoutComponent from '../../Layout/LayoutComponent';

function VideoComponent(props){
    return(
        <Fragment>
            <LayoutComponent page={"videos"} item={props.location.state.data}/>
        </Fragment>
        
    )
}

export default VideoComponent;
