import React from 'react';
import './ItemBigSliderComponent.css';
import { env } from '../../../config';

function ItemBigSliderComponent(props){
    return (
        <div 
            id={'1_'+props.index}
            className="wrapper-item-slider" 
            style={{ background: "url("+env.cloudfrontUrl+props.item.defaultImage+env.qualityBigSlider+") center center / cover"}}
        >
            <div style={{width: '100%'}}>
                <span className="slider-title">{props.item.title}</span>
            </div>
        </div>        
    )
}

export default ItemBigSliderComponent;