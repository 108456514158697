import React from 'react';
import '../Account/AccountComponent.css';

function AppInfoComponent(props){
    return (
        <div className="wrapper-account">
            <div> 
                <span className="settings_title">APP INFO</span>
            </div>
            <div>
                <span className="settings_content_item moving-up">Application version: 1.0.0</span>
            </div>
        </div>
    )
}

export default AppInfoComponent;