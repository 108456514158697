import React from 'react';
import './ItemCarouselComponent.css';

function ItemCarouselComponent(props){
    return(
        <div 
            id={props.id_control+'_'+props.index}
            className="container-item-carousel" 
            style={{ background: "url("+props.backgroundImage +") center center / cover" }}
            onMouseEnter={props.getItemDetail ? () => props.getItemDetail(props.item) : null}
        >
            <div className="duration-label">
                <span className="title-name">
                    {
                        props.item.duration ? 
                            props.item.duration 
                        : 
                            props.item.episodesCount ? 
                                props.item.episodesCount 
                            : 
                                props.item.playlistCount
                    }
                </span>
            </div>
        </div>
    )
}

export default ItemCarouselComponent;