
import React, { Fragment } from 'react';
import axios from 'axios';
import './VewdPlayer.css';

class VewdPlayer extends React.PureComponent {
    player = null;
    
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.videoReady = false;

        this.state = {
            canPlay: true
        }
    }

    componentDidMount() {
        this.video = this.player.current;
        this.addListeners();
        this.canPlay();
    }

    componentWillUnmount() {
        this.removeListeners();
    }

    addListeners () {
        const { onPlay, onBuffer, onPlaying, onPause, onSeek, onEnded, onError, onProgress } = this.props;
        this.video.addEventListener('canplay', this.onReady);
        this.video.addEventListener('play', onPlay);
        this.video.addEventListener('waiting', onBuffer);
        this.video.addEventListener('pause', onPause);
        this.video.addEventListener('seeked', onSeek);
        this.video.addEventListener('error', onError);
        this.video.addEventListener('playing', onPlaying);
        this.video.addEventListener('ended', onEnded);
        this.video.addEventListener('progress', onProgress);
    }

    removeListeners () {
        const { onPlay, onBuffer, onPlaying, onPause, onSeek, onEnded, onError, onProgress} = this.props;
        this.video.removeEventListener('canplay', this.onReady);
        this.video.removeEventListener('play', onPlay);
        this.video.removeEventListener('waiting', onBuffer);
        this.video.removeEventListener('pause', onPause);
        this.video.removeEventListener('seeked', onSeek);
        this.video.removeEventListener('error', onError);
        this.video.removeEventListener('playing', onPlaying);
        this.video.removeEventListener('ended', onEnded);
        this.video.removeEventListener('progress', onProgress);
    }

    onReady = () => {
        if (!this.videoReady) {
            this.videoReady = true;
            if (this.props.start > 0) {
                this.seekTo(this.props.start);
            }
            this.play();
        }
    }

    getCurrentTime () {
        if (!this.video) return null;
        return this.video.currentTime;
    }

    getDuration () {
        if (!this.video) return null;
        const { duration, seekable } = this.video;
        // on iOS, live streams return Infinity for the duration
        // so instead we use the end of the seekable timerange
        if (duration === Infinity && seekable.length > 0) {
          return seekable.end(seekable.length - 1);
        }
        return duration;
    }

    play() {
        const promise = this.video.play();
        if (promise) {
          promise.catch(this.props.onError);
        }
    }

    pause() {
        this.video.pause();
    }

    stop () {
        this.video.removeAttribute('src');
    }

    seekTo (seconds) {
        this.video.currentTime = seconds;
    }

    formattingCaptions = (captions) => {
        const captionsJson = JSON.parse(this.props.captions);
        captionsJson.map((elm, idx) => {
            axios({
                url: elm.file,
                method: 'GET',
                responseType: 'blob',
            })
            .then(async response => {
                let url = window.URL.createObjectURL(new Blob([response.data]));
                let parentElement = document.getElementById('video_player');

                //Including track (captions) inside the <video> tag
                let trackElement = document.createElement('track');
                trackElement.setAttribute('kind', elm.kind);
                trackElement.setAttribute('src', url);
                trackElement.setAttribute('label', elm.label);
                trackElement.setAttribute('srclang', elm.language);
                trackElement.setAttribute('default', elm.default);
                parentElement.appendChild(trackElement);
                parentElement.textTracks[0].mode = 'showing';
            })
            .catch(error => {

            })  
            return elm;
        })
    }

    canPlay = () => {
        if (this.props.is4k) {
            if (!this.video.canPlayType('video/mp4; codecs="hev1"').replace(/^no$/, '')) {
                this.setState({canPlay: false});
            }
        }    
    }

    render(){
        return (
            <div className="vewdPlayerWrapper">
                <Fragment>
                    <video ref={this.player} id="video_player" controls>
                    {
                        !this.props.is4k ?
                            <source src={this.props.url} type="application/vnd.apple.mpegurl" /> :
                            <source src={this.props.dashurl} type="application/dash+xml" />
                    }
                    </video>
                    { this.formattingCaptions(this.props.captions) }
                </Fragment>
                { !this.state.canPlay ?
                    <div className="notSupported">
                        Video Format is not supported by this device. 
                    </div>
                    :
                    null
                }
		    </div>
        )
    }
    
}

export default VewdPlayer;