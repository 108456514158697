import React from 'react';
import { withRouter } from 'react-router-dom';
import './DeviceSigninComponent.css';
import ActivationService from '../../services/ActivationService';
import GeneralComponent from '../GeneralComponent';
import { keyCode, env, urlRoutes} from '../../config';

class DeviceSigninComponent extends GeneralComponent {

    constructor(props) {
        super(props);
        this.state = {
            codeActivation: ''
        }
        this.intervalId = '';
        this._isMount = false;
    }

    componentDidMount() {
        this._isMount = true;
        this.recognizeKeyDown();
        setTimeout(()=>{
            this.Controls.moveDown("device-signin", "color-hover-button", "color-hover-button");
        }, 10);

        this.handleDeviceLinking();
    }

    componentWillUnmount(){
        this.cleanEvents();
    }

    cleanEvents = () => {
        clearInterval(this.intervalId);
        this._isMount = false;
        this.unmountListener();
        localStorage.removeItem('regcode');
    }
    
    handleActivationCode = (activationCode) => {
        this._isMount && this.setState({
            codeActivation: activationCode.toString()
        })
        localStorage.setItem('regcode', activationCode.toString());
        this.intervalId = setInterval(()=> {
            ActivationService.verifyActivationCode(localStorage.getItem('regcode'), this.handleVerificationCode);
        }, 2000);
    }

    handleDeviceLinking = (response) => {
        ActivationService.getActivationCode(this.handleActivationCode);
    }

    handleVerificationCode = (response) => {
        // The code was verified by the API, I should activate
        if(response.data.linkStatus){
            localStorage.setItem('authorizeToken',response.data.userData.authorizeToken);
            localStorage.setItem('deviceToken',response.data.userData.deviceToken);
            localStorage.setItem('refreshToken', response.data.userData.refreshToken);
            localStorage.removeItem('regcode');
            clearInterval(this.intervalId);
            this.props.history.push(urlRoutes.featured);
        }
    }

    useControls = (keyPressed) => {

        if (this.onBackButton(keyPressed, 'GoBack')) {
            return;
        }

        switch (keyPressed) {
            case keyCode.enter:
                if (this.Controls.getCurrentElement() === 0) {
                    clearInterval(this.intervalId);
                    ActivationService.getActivationCode(this.handleActivationCode);
                }
                else if (this.Controls.getCurrentElement() === 1) {
                    this.cleanEvents();
                    this.props.history.goBack();
                }
                break;
            case keyCode.up:
                this.Controls.moveUp("device-signin", "color-hover-button", "color-hover-button");
                break;
            case keyCode.down:
                this.Controls.moveDown("device-signin", "color-hover-button", "color-hover-button");
                break;
            default:
                return;
        }
    }

    render() {
        return (
            <div className="wrapper-device-signin">
                <div>
                    <div className="link-account">
                        LINK YOUR ACCOUNT
                    </div>
                    <div className="device-link-subtitle">
                        From your computer or mobile device, go to:
                    </div>
                    <div className="device-link-samsung">
                        {env.linkMagellan}
                    </div>
                    <div className="device-description">
                        Follow the onscreen instructions and enter the following activation code to complete your registration.
                    </div>
                </div>
                <div className="container-form-code">
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className="linking-code">
                            LINKING CODE
                        </div>
                        <div className="code-line-text">
                            {this.state.codeActivation}
                        </div>
                        <div className="divide-line"></div>
                        <div className="update-successful">
                            This screen will automatically update if linking your account is successful.
                        </div>
                    </div>
                    <div id="controls_0" className="wrapper-panel-buttons">
                        <div id="0_0" className="button-wrapper">
                            <div>
                                GET A NEW CODE
                            </div>
                        </div>
                        <div id="0_1" className="button-wrapper">
                            <div>
                                CANCEL
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default withRouter(DeviceSigninComponent);