import { env } from '../../config';
import ShakaPlayer from './Players/ShakaPlayer';
import ReactPlayer from './Players/ReactPlayer';
import VewdPlayer from './Players/VewdPlayer';
// Import other player here

var player;

switch (env.defaultPlayer) {
    case 'shaka':
        player = ShakaPlayer;
        break;
    case 'react':     
        player = ReactPlayer;
        break;
    case 'vewd':
        player = VewdPlayer;
        break;
    default:
        player = ReactPlayer;        
        break;
}

export default player;