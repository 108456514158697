import React, { Fragment } from 'react';
import LayoutComponent from '../Layout/LayoutComponent';

function GenresComponent(props){
    return(
        <Fragment>
            <LayoutComponent page={"genres"} />
        </Fragment>
    )
}

export default GenresComponent;