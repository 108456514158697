import React from 'react';
import './NavbarContentComponent.css';
import { withRouter } from 'react-router-dom';

function NavbarContentComponent(props) {
    return (
        <div
            id={"controls_0"}
            className={"navbar-wrapper"}
            tabIndex={"1"}
        >
            <div
                className={"default-wrapper"}
                id={props.allIds.featured}
                tabIndex={"1"}
            >
                <span>Featured</span>
            </div>
            <div
                className={"default-wrapper"}
                id={props.allIds.explore}
                tabIndex={"1"}
            >
                <span>Explore</span>
            </div>
            <div
                className={"tab-space default-wrapper"}
                id={props.allIds.genres}
                tabIndex={"1"}
            >
                <span>Genres</span>
            </div>
            {
                <div
                    className={"tab-space default-wrapper svg-position"}
                    id={props.allIds.search}
                    tabIndex={"1"}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="28.498" height="28.49" viewBox="0 0 38.498 38.49">
                        <path id="icon-nav-search" d="M37.77 34.23l-10.4-10.39c-.05-.05-.11-.09-.17-.14a15.04 15.04 0 1 0-3.5 3.5c.05.06.09.12.14.17l10.39 10.39a2.5 2.5 0 0 0 3.54-3.53zM3 15a12 12 0 1 1 12 12A12.01 12.01 0 0 1 3 15z" className="cls-1" />
                    </svg>
                </div>
            }
            {
                !localStorage.getItem('authorizeToken') ? 
                    <div className={"tab-space default-wrapper"} id={props.allIds.tryfree}>
                        <span>TRY FREE</span>
                    </div>
                : null
            }
            {
                localStorage.getItem('authorizeToken') ? 
                    <div
                        className={"tab-space default-wrapper"}
                        id={props.allIds.settings}
                        tabIndex={"1"}
                    >
                        <span>Settings</span>
                    </div>
                : null
            }
        </div>
    )
}

export default withRouter(NavbarContentComponent);
