import React from 'react';
import '../Account/AccountComponent.css';

function LegalComponent(props){
    return (
        <div className="wrapper-account">
            <div>
                <span className="settings_title">LEGAL</span>
            </div>
            <div>
                <span className="settings_content_item">FAQ:</span>
                <span className="settings_content_item moving-up">https://www.magellantv.com/faq</span>
            </div>
            <div className="moving-up">
                <span className="settings_content_item">Privacy Policy:</span>
                <span className="settings_content_item moving-up" >https://www.magellantv.com/privacy_policy</span>
            </div>
            <div className="moving-up-terms">
                <span className="settings_content_item">Terms of Use:</span>
                <span className="settings_content_item moving-up">https://www.magellantv.com/terms_conditions</span>
            </div>
            <div className="moving-up-about">
                <span className="settings_content_item">About:</span>
                <span className="settings_content_item moving-up">https://www.magellantv.com/about</span>
            </div>
        </div>    
    )
}

export default LegalComponent;