import React, { Fragment } from 'react';
import LayoutComponent from '../Layout/LayoutComponent';

function FeaturedComponent(props){
    return (
        <Fragment>
            <LayoutComponent page={"featured"}/>        
        </Fragment>
    )
}

export default FeaturedComponent;