import axios from 'axios';
import  { env, urlApiCalls } from '../config';

export class ActivationService {
    
    /**
    * Service to get the first token needed to ask for the activation code
    */
    static getBrowseToken = async () => {
        return await axios.get(env.apiUrl+urlApiCalls.browseToken);
    }

    // This function is not used anymore since Device Linking API migration
    // static getDeviceLinkingToken = async (resolve) => {
    //     return await axios.post(env.activationUrl + urlApiCalls.tokenDeviceLinking, apiDevKeys)
    //     .then(response => {
    //         resolve(response);
    //     })
    //     .catch(error => {
    //         console.log('Error:: API DeviceLinking -->', error.response);
    //     });
    // }
    
    /**
    * Service to get the activation code to send every 2 seconds
    * to verify if the user activated the Smart Tv.
    */
    static getActivationCode = (resolve) => {
        let deviceToken = {"deviceToken": "device123"};
        let encodedToken = encodeURIComponent(JSON.stringify(deviceToken));

        axios.get(env.apiUrl + urlApiCalls.activationCode + encodedToken, {
            headers: {
                browseToken: localStorage.getItem('browseToken')
            }
        })
        .then(response => {
            if (response.status === 200){
                resolve(response.data.regcode);
            }
        })
        .catch(error => {
            console.log('Error:: API DeviceLinking -->', error.response);
            localStorage.removeItem('token');
        })
    }

    /** 
    * Service to re-authenticate the user when the token expires
    * @param :: regcode --> code to verify each 2 seconds to activate the screen
    * @param :: resolve --> function called when the response is received
    */
    static verifyActivationCode = (regcode, resolve) => {
        axios.get(env.apiUrl + urlApiCalls.statusDeviceLinking + regcode, {
            headers: {
                browseToken: localStorage.getItem('browseToken')
            }
        })
        .then(response => {
            resolve(response);
        })
        .catch(error => {
            console.log('Error:: API DeviceLinking -->', error.response);
            localStorage.removeItem('token');
        })
    }

    /** 
    * Service to re-authenticate the user when the token expires
    */
    static reAuthenticate = () => {
        return new Promise((accept,reject) => {
            let data = {
                refreshToken: localStorage.getItem('refreshToken'),
                deviceToken: "device123",
                grantType: "re-authenticate"
            }
            axios.post(env.apiUrl+urlApiCalls.revoke, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                if(response.status === 200){
                    localStorage.setItem('authorizeToken',response.data.authorizeToken);
                    localStorage.setItem('refreshToken',response.data.refreshToken);
                    accept(true);
                } else {
                    localStorage.removeItem('authorizeToken');
                    localStorage.removeItem('refreshToken');
                    reject(response);
                    window.location = '/paywall';
                }
            }).catch(error => {
                localStorage.removeItem('authorizeToken');
                localStorage.removeItem('refreshToken');
                reject(error);
                window.location = '/paywall';
            });
        })
    }
}

export default ActivationService;