import React from 'react';
import RouterWrapper from './containers/RouterWrapper';
import './assets/fonts/style.css';

function App() {
  return (
    <RouterWrapper />
  );
}

export default App;
