import React, { Fragment } from 'react';
import './PaywallComponent.css';
import GeneralComponent from '../GeneralComponent';
import { urlRoutes, keyCode } from '../../config';
import LoaderComponent from '../Shared/Loader/LoaderComponent';

class PaywallComponent extends GeneralComponent {

    constructor(props){
        super(props);
        this.state = {
            loading: true
        }
    }

    componentDidMount() {
        if(localStorage.getItem('authorizeToken')){
            this.props.history.push(urlRoutes.featured);
        }   
        else {
            this.recognizeKeyDown();
            this.setState({
                loading: false
            });
            // Active the first button of the page
            setTimeout( () => {
                this.Controls.moveDown("paywall", "color-button", "color-button");
            }, 10)
        }
    }
    
    componentWillUnmount(){
        this.unmountListener();
    }

    useControls = (keyPressed) => {

        if (this.onBackButton(keyPressed, 'Exit')) {
            return;
        }
        
        switch (keyPressed) {
            case keyCode.enter:
                switch (this.Controls.getCurrentElement()) {
                    case 0:
                        this.Controls.pressEnter(this.props, urlRoutes.deviceSignUp);
                        break;
                    case 1:
                        this.Controls.pressEnter(this.props, urlRoutes.featured);
                        break;
                    case 2:
                        this.Controls.pressEnter(this.props, urlRoutes.deviceSignIn);
                        break;
                    default:
                        return;
                }
                break;
            case keyCode.back:
            case keyCode.backAlt:
                this.props.history.push(urlRoutes.exitScreen);
                break;
            case keyCode.up:
                this.Controls.moveUp("paywall", "color-button", "color-button");
                break;
            case keyCode.down:
                this.Controls.moveDown("paywall", "color-button", "color-button");
                break;
            default:
                return;
        }
    }

    render() {
        return (
            this.state.loading ? 
                <Fragment>
                    <LoaderComponent />
                </Fragment>
            :
                <div className="wrapper-paywall">
                    <div className="wrapper-logo">
                        <img 
                            width="280"
                            height="150"
                            src="https://magellantv-smart-tv-assets.s3.amazonaws.com/images/client-logo.png"
                            alt="Main Magellan tv logo"
                        />
                    </div>
                    <div className="banner-text">
                        <h1 className="banner-text">DOCUMENTARIES WORTH WATCHING</h1>
                    </div>
                    <div className="banner-subtitle">
                        WATCH THOUSANDS OF GREAT DOCUMENTARIES, WITH PLANS AS LOW AS $4.99 PER MONTH.
                    </div>
                    <div id="controls_0" className="wrapper-button">
                        <div>
                            <button id="0_0" className="button-box">TRY FREE</button>
                        </div>
                        <div>
                            <button id="0_1" className="button-box">BROWSE</button>
                        </div>
                        <div>
                            <button id="0_2" className="button-box">LOG IN</button>
                        </div>
                    </div>
                </div>
        )
    }
}

export default PaywallComponent;