import Samsung from './Remote/Samsung'
import Keyboard from './Remote/Keyboard'
// Import other remote device here

export class RemoteService {
    
    component = null;
    
    /**
     * Add listeners for remote device keys
     * @param React.ref component
     */
    static registerRemote = (component) => {
        this.component = component;
        document.addEventListener('keydown', this.keyEvent, false);
        this.registerKeyCodes();
    }

    /**
     * Remove listeners for remote device keys
     */    
    static unregisterRemote = () => {
        document.removeEventListener('keydown', this.keyEvent, false);
        this.unregisterKeyCodes();
    }
    
    /**
     * Return device object class
     * @returns {Keyboard|Samsung}
     */
    static getDevice(){
        // TODO call methods to detect other devices such vewd or LG
        return typeof window.tizen === 'undefined' ? Keyboard : Samsung;
    }
    
    /**
    * Execute method for pressed key from object class
    * @param {type} ev
    * @returns {undefined}
    */
    static keyEvent = (ev) => {
        let remote = this.getDevice();
        remote.useControls(ev.keyCode, this.component);
    }
    
    /**
     * Register special keycodes for each remote device
     */    
    static registerKeyCodes(){
        let remote = this.getDevice();
        remote.registerKeyCodes();
    }
    
    /**
     * Unregister special keycodes for each remote device
     */    
    static unregisterKeyCodes(){
        let remote = this.getDevice();
        remote.unregisterKeyCodes();
    }
    
}   

export default RemoteService;