import React from 'react';
import ReactPlayer from 'react-player';
import './ReactPlayer.css';

class ReactPlayerComponent extends React.PureComponent{

    player = null;
    
    constructor(props) {
        super(props);
        this.player = React.createRef();
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    play () {
    }
    
    pause () {
    }  
    
    stop () {
    }
    
    seekTo (seconds) {
    }
    
    setVolume (fraction) {
    }
    
    mute = () => {
    }
    
    unmute = () => {
    }    
    
    setPlaybackRate (rate) {
    }    
    
    getDuration () {  
    }

    getCurrentTime () {
    }
    
    getSecondsLoaded () {
    }
    
    render(){
        return (
            <ReactPlayer
                ref={this.player}
                id={"video_player"}
                className='react-player'
                url={this.props.url}
                width={this.props.width}
                height={this.props.height}
                playing={this.props.playing}
                controls={this.props.controls}
                pip={this.props.pip}
                onReady={this.props.onReady}
                onProgress={this.props.onProgress}
            />    
        )
    }
    
}

ReactPlayerComponent.defaultProps = {
  start: 0,
  url: '',
  width: '100%',
  height: '100%',
  playing : false,
  controls : true,
  pip : false
};

export default ReactPlayerComponent;