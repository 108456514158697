import React from 'react'
import DotComponent from './DotComponent'
import './DotsWrapper.css';

/**
 * Component of slider dots
 * @param index : index of selected dot
 * @param dots : to show one dot per image
 * @param dotClick : method when clicks on dot
 */

function DotsWrapper(props){
  return (
    <div id={"controls_1"} className="dots-container">
      {
        props.dots.map((elm, idx) => {
          return (
            <DotComponent 
              key={idx}
              active={props.index === idx ? true: false}
              dotClick={props.dotClick}
              index={idx}
            />
          )
        })
      }
    </div>
  )  
}

export default DotsWrapper;