import React, { Fragment } from 'react';
import LayoutComponent from '../Layout/LayoutComponent';

function SettingsComponent(props){

    return (
        <Fragment>
            <LayoutComponent page={"settings"} />
        </Fragment>
    )
}

export default SettingsComponent;