import React from 'react';
import { withRouter } from 'react-router-dom';
import SideMenuComponent from '../../Shared/SideMenu/SideMenuComponent';
import AccountComponent from '../Account/AccountComponent';
import './SettingsWrapperComponent.css';
import LegalComponent from '../Legal/LegalComponent';
import AppInfoComponent from '../AppInfo/AppInfoComponent';
import LoaderComponent from '../../Shared/Loader/LoaderComponent';
import GeneralComponent from '../../GeneralComponent';
import { keyCode, urlRoutes } from '../../../config';

class SettingsWrapperComponent extends GeneralComponent {
    
    constructor(props){
        super(props);
        this.state = {
            genres: [
                {genresId: 0, title: "ACCOUNT"}, 
                {genresId: 1, title: "LEGAL"}, 
                {genresId: 2, title: "APP INFO"}
            ],
            elementActivate: "ACCOUNT"
        }
        this._isMount = false;
    }

    componentDidMount(){
        this._isMount = true;
        this.props.toggleLoader(false);
        this.props.changeBackground({
            featuredHeroWeb: 'try-free-bg.jpg', 
            isSettings: true
        });
        this._isMount && this.recognizeKeyDown();
        this._isMount && setTimeout(() => {
            this.Controls.moveDown('settings');
        }, 10)
    }

    logout = () => {
        localStorage.clear();
        this.props.history.push(urlRoutes.paywall);
    }

    componentWillUnmount(){
        this.unmountListener();
        this._isMount = false;
        this.props.toggleLoader(true);
    }

    activeElement = (idx, elm) => {
        this._isMount && this.setState({
            elementActivate: elm.title
        });
    }

    useControls = (keyPressed) => {

        if (this.onBackButton(keyPressed, 'Exit')) {
            return;
        }
        
        switch(keyPressed){
            case keyCode.down:
                if(this.Controls.getCurrentControl() === 0){
                    this.hiddenMenu();
                    document.getElementById(this.Controls.activeSlider.toString()+'_'+this.Controls.activeElement.toString()).classList.remove('hover-background');
                    this.Controls.activeSlider += 1; 
                    this.Controls.activeElement = 0;
                    document.getElementById(this.Controls.activeSlider.toString()+'_'+this.Controls.lastMenuTab.toString()).classList.add('active-side-tab');
                }
                else if(this.Controls.getCurrentControl() === 1){
                    if(this.Controls.lastMenuTab < 2){
                        if(this.Controls.lastMenuTab === 0){
                            this._isMount && this.setState({
                                elementActivate: 'LEGAL'
                            })
                        }
                        else if(this.Controls.lastMenuTab === 1){
                            this._isMount && this.setState({
                                elementActivate: 'APP INFO'
                            })    
                        }

                        document.getElementById(this.Controls.activeSlider.toString()+'_'+this.Controls.lastMenuTab.toString()).classList.remove('active-side-tab');
                        this.Controls.lastMenuTab += 1;
                        document.getElementById(this.Controls.activeSlider.toString()+'_'+this.Controls.lastMenuTab.toString()).classList.add('active-side-tab');
                    }
                }
                break;
            case keyCode.up:
                if(this.Controls.getCurrentControl() === 1){
                    document.getElementById(this.Controls.activeSlider.toString()+'_'+this.Controls.lastMenuTab.toString()).classList.remove('active-side-tab');

                    if(this.Controls.lastMenuTab !== 0){
                        if(this.Controls.lastMenuTab === 1){
                            this._isMount && this.setState({
                                elementActivate: 'ACCOUNT'
                            })
                        }
                        else if(this.Controls.lastMenuTab === 2){
                            this._isMount && this.setState({
                                elementActivate: 'LEGAL'
                            })    
                        }
                        this.Controls.lastMenuTab -= 1;
                        document.getElementById(this.Controls.activeSlider.toString()+'_'+this.Controls.lastMenuTab.toString()).classList.add('active-side-tab');
                    }
                    else {
                        this.Controls.activeSlider = 0;
                        this.Controls.activeElement = 4;
                        document.getElementById(this.Controls.activeSlider.toString()+'_4').classList.add('hover-background');    
                        this.showMenu();    
                    }
                } 
                break; 
            case keyCode.right:
                if(this.Controls.getCurrentControl() === 0){
                    document.getElementById('0_4').children[0].classList.add('active-tab');
                    this.Controls.moveRight('featured', 'hover-background');
                }
                else if(this.Controls.getCurrentControl() === 1 && this.Controls.lastMenuTab === 0){
                    document.getElementById(this.Controls.activeSlider.toString()+'_'+this.Controls.lastMenuTab.toString()).classList.remove('active-side-tab');
                    this.Controls.activeSlider += 1;
                    this.Controls.activeElement = 0;
                    document.getElementById('2_0').classList.add('active-button-login');
                }
                break;
            case keyCode.left:
                if(this.Controls.getCurrentControl() === 0){
                    document.getElementById('0_4').children[0].classList.add('active-tab');
                    this.Controls.moveLeft('featured', 'hover-background');
                }
                else if(this.Controls.getCurrentControl() === 2 && this.Controls.activeElement === 0){
                    this.Controls.activeSlider -= 1;
                    this.Controls.activeElement = 0;
                    document.getElementById(this.Controls.activeSlider.toString()+'_'+this.Controls.lastMenuTab.toString()).classList.add('active-side-tab');
                    document.getElementById('2_0').classList.remove('active-button-login');
                }
                break; 
            case keyCode.enter:
                if(this.Controls.getCurrentControl() === 0){
                    this.clickOnHeader();
                }
                else if(this.Controls.getCurrentControl() === 2){
                    if ( localStorage.getItem('authorizeToken') ) {
                        this.logout();
                    }    
                    else {
                        this.props.history.push(urlRoutes.deviceSignIn);
                    }
                } 
                break;
            default:
                return;
        }
        
    }
    
    render(){ 
        return(
            this.props.loading ? 
                <LoaderComponent />
            :
                <div className="wrapper-settings">
                    <div className="inner-settings">
                        <SideMenuComponent 
                            data={this.state.genres.length !== 0 ? this.state.genres: []} 
                            isSettings={true}
                            activeElement={this.activeElement}
                        />
                        {
                            this.state.elementActivate === "ACCOUNT" ?
                                <AccountComponent logout={this.logout}/>
                            :
                                this.state.elementActivate === "LEGAL" ?
                                    <LegalComponent />
                                :
                                    this.state.elementActivate === "APP INFO" ?
                                        <AppInfoComponent />
                                    :
                                        null
                        }
                    </div>
                    <div 
                        className={"footer-content"} 
                    >
                        <div className="info-container">
                        <span className="contact-magellan">For any questions, please email us at connect@magellantv.com</span>
                        </div>
                        <div>
                            <span className="copyright">© MagellanTV, LLC 2019</span> 
                        </div>
                    </div>
                </div>     
        )
    }
}

export default withRouter(SettingsWrapperComponent);