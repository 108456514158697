import React, { Fragment } from 'react';
import LayoutComponent from '../Layout/LayoutComponent';

function SearchComponent(props){
    return (
        <Fragment>
            <LayoutComponent page={"search"}/>
        </Fragment>
    )
}

export default SearchComponent;