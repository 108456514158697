import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import './GenresWrapperComponent.css';
import GeneralComponent from '../../GeneralComponent';
import SideMenuComponent from '../../Shared/SideMenu/SideMenuComponent';
import { env, urlApiCalls, keyCode, genresConf } from '../../../config';
import axios from 'axios';
import ActivationService from '../../../services/ActivationService';
import LoaderComponent from '../../Shared/Loader/LoaderComponent';
import ItemCarouselComponent from '../../Shared/ItemCarousel/ItemCarouselComponent';
import LoaderOverlayComponent from '../../Shared/LoaderOverlay/LoaderOverlayComponent';

class GenresWrapperComponent extends GeneralComponent{

    constructor(props){
        super(props);
        this.state = {
            genres: null,
            genresContentList: []
        }
        this._isMount = false;
        this.firstTime = false;
        this.row = 0;

        this.idTimeoutRestoreScreen = 0;
        this.restoreScreenData = {
            backPressed: false,
            control: 0,
            element: 0,
            currentGenreId: null
        }

        if (this.props.history.location.state) {
            this.restoreScreenData.control = this.props.history.location.state.currentControl;
            this.restoreScreenData.element = this.props.history.location.state.currentElement;
            this.restoreScreenData.backPressed = this.props.history.location.state.backPressed;
            this.restoreScreenData.currentGenreId = this.props.history.location.state.genreId;
            this.state.showLoadingOverlay = true;
        }

    }

    componentDidMount(){
        this._isMount = true;
        this.Controls.activeSlider = 0;
        this.Controls.activeElement = 2;

        if (localStorage.getItem('browseToken')) {

            if(localStorage.getItem('genres')){
                this._isMount && this.setState({
                    genres: JSON.parse(localStorage.getItem('genres')),
                });
                let genreId = JSON.parse(localStorage.getItem('genres'))[0].genresId;
                if (this.restoreScreenData.currentGenreId) {
                    genreId = this.restoreScreenData.currentGenreId;
                }
                this.getGenresById(genreId);
            }
            else {
                this.getGenresData(localStorage.getItem('browseToken'));
            }
        }
        else {
            ActivationService.getBrowseToken()
            .then(response => {
                if (response.status === 200){
                    this.getGenresData(response.data.browseToken);
                } 
            })
            .catch(error => {
                console.log("Error generating the browse token :::", error);
            })
        }
        this.recognizeKeyDown();
        if (this.restoreScreenData.backPressed) {
            this.restoreScreen();
        }
    }


    componentWillUnmount(){
        this.unmountListener();
        this._isMount = false;
        this.props.toggleLoader(true);
        clearInterval(this.idTimeoutRestoreScreen);
    }

    calcItemsPerRow = (element) => {
        if (element) {
            let itemsPerRow = Math.floor(element.offsetWidth / genresConf.itemWidth);
            this.Controls.initGrid(itemsPerRow);
        }
    }

    changeCurrentGenre = (prevGenreId) => {
        setTimeout( (prevGenresId) => {
            let genreId = JSON.parse(localStorage.getItem('genres'))[this.Controls.lastMenuTab].genresId;
            if (prevGenresId === genreId) {
                this.getGenresById(JSON.parse(localStorage.getItem('genres'))[this.Controls.lastMenuTab].genresId); 
            }
        }, 600, prevGenreId);
    }

    restoreScreen = () => {
        this.idTimeoutRestoreScreen = setInterval(() => {
            let controlVal = this.restoreScreenData.control;
            let elementVal = this.restoreScreenData.element;
            let menuElem = document.getElementById('controls_1');
            let menuScrolled = menuElem ? menuElem.classList.contains('menu-ready') : false;

            if (menuElem && !menuScrolled) {
                let genreIndex = -1;
                JSON.parse(localStorage.getItem('genres')).forEach( (genre, index)  => {
                    if (genre.genresId === this.restoreScreenData.currentGenreId) {
                        genreIndex = index;
                    }
                });  
                
                if (document.getElementById('1_' + genreIndex)) {
                    this.useControls(keyCode.down);
                    for (let i = 0; i < genreIndex; i++) {
                        this.Controls.moveDown('genres', 'active-side-tab');
                    }
                    menuScrolled = true;
                    menuElem.classList.add('menu-ready');
                }
            }
            
            if (menuScrolled) {
                if (document.getElementById(controlVal + '_' + elementVal) && (this.Controls.getCurrentElement() !== elementVal || elementVal === 0)) {
                    this.useControls(keyCode.right);
                    
                    let rowIndex = Math.floor(elementVal / this.Controls.getItemsPerRow());
                    for (let i = 0; i < rowIndex; i++) {
                        this.useControls(keyCode.down);
                    }

                    let columnIndex = elementVal % this.Controls.getItemsPerRow();
                    for (let i = 0; i < columnIndex; i++) {
                        this.useControls(keyCode.right);
                    }

                    this.setState({
                        showLoadingOverlay: false
                    });
                    clearInterval(this.idTimeoutRestoreScreen);
                } 

            }

        }, 100);
    }

    useControls = (keyPressed) => {

        if (this.onBackButton(keyPressed, 'Exit')) {
            return;
        }
        
        switch(keyPressed){
            case keyCode.down:
                if(this.Controls.getCurrentControl() === 0){
                    this.hiddenMenu(); 
                    this.Controls.moveDown('genres', 'active-side-tab' ,'hover-background');     
                }
                else if (this.Controls.getCurrentControl() === 1) {
                    this.Controls.moveDown('genres', 'active-side-tab');
                    this.changeCurrentGenre(JSON.parse(localStorage.getItem('genres'))[this.Controls.lastMenuTab].genresId);
                    this.lastSliderActive = 0;
                }
                else {
                    let elements = document.getElementById('carousel_menu').childElementCount;
                    if(this.Controls.lastSliderActive + this.Controls.getItemsPerRow() < elements){
                        this.Controls.moveDown('genres', 'select-item-carousel');
                    }
                }
                break;
            case keyCode.up:
                if(this.Controls.getCurrentControl() === 0){
                    return;   
                }
                else if(this.Controls.getCurrentControl() === 1){
                    // The header has to appear
                    if(this.Controls.getCurrentElement() === 0){
                        this.Controls.moveUp('genres', 'hover-background', 'active-side-tab');
                        this.showMenu();
                        this.lastSliderActive = 0;
                    }
                    else {
                        this.lastSliderActive = 0;
                        this.Controls.moveUp('genres', 'active-side-tab');  
                        this.changeCurrentGenre(JSON.parse(localStorage.getItem('genres'))[this.Controls.lastMenuTab].genresId);
                    }
                }
                else {
                    if(this.Controls.lastSliderActive <= (this.Controls.getItemsPerRow() - 1)){
                        document.getElementById(this.Controls.activeSlider.toString()+'_'+this.Controls.activeElement.toString()).classList.remove('select-item-carousel');
                        document.getElementById("0_2").classList.add('hover-background');
                        document.getElementById("1_"+this.Controls.lastMenuTab).classList.remove('active-elm');
                        this.showMenu();
                        this.Controls.activeSlider = 0;
                        this.Controls.activeElement = 2;
                        this.Controls.lastSliderActive = 0;  
                    }
                    else {
                        this.Controls.moveUp('genres', 'select-item-carousel');
                    }
                }
                break;
            case keyCode.right:
                if(this.Controls.getCurrentControl() === 0){
                    if(this.Controls.getCurrentElement() === 2){
                        document.getElementById(this.Controls.getCurrentControl().toString()+'_2').classList.remove('hover-background');
                        document.getElementById(this.Controls.getCurrentControl().toString()+'_2').children[0].classList.add('active-tab');
                    }
                    if(!document.getElementsByClassName('active-tab')[0]){
                        document.getElementById(this.Controls.getCurrentControl().toString()+'_2').children[0].classList.add('active-tab');
                    }
                    this.Controls.moveRight('genres', 'hover-background');    
                }
                else if(this.Controls.getCurrentControl() === 1){
                    document.getElementById(this.Controls.activeSlider.toString()+'_'+this.Controls.lastMenuTab.toString()).classList.add('active-elm');
                    this.Controls.moveRight('genres', 'select-item-carousel', 'active-side-tab')
                }
                else {
                    let itemsPerRow = this.Controls.getItemsPerRow();
                    if(this.Controls.lastSliderActive <= itemsPerRow && this.Controls.lastSliderActive !== (itemsPerRow - 1) ){
                        this.Controls.moveRight('genres', 'select-item-carousel');
                    }
                    else if(this.Controls.lastSliderActive === (itemsPerRow - 1)){
                        return;
                    }
                    else if( (this.Controls.lastSliderActive+1) - itemsPerRow % itemsPerRow === 0){
                        return;
                    }
                    else {
                        this.Controls.moveRight('genres', 'select-item-carousel');    
                    }
                }
                break;
            case keyCode.left:
                if(this.Controls.getCurrentControl() === 0){
                    if(this.Controls.getCurrentElement() === 2){
                        document.getElementById(this.Controls.getCurrentControl().toString()+'_2').children[0].classList.add('active-tab'); 
                        document.getElementById(this.Controls.getCurrentControl().toString()+'_2').classList.remove('hover-background');
                    }
                    this.Controls.moveLeft('genres', 'hover-background'); 
                }
                else if(this.Controls.getCurrentControl() === 2){
                    
                    if(this.Controls.lastSliderActive % this.Controls.getItemsPerRow() === 0 || this.Controls.lastSliderActive === 0){
                        this.Controls.moveLeft('genres', 'active-side-tab' ,'select-item-carousel');    
                    }
                    else {
                        this.Controls.moveLeft('genres', 'select-item-carousel');
                    }
                }
                break;
            case keyCode.enter:
                if (this.Controls.getCurrentControl() === 2){
                    this.props.history.replace('/genres', {
                        currentControl: this.Controls.getCurrentControl(), 
                        currentElement: this.Controls.getCurrentElement(),
                        backPressed: true,
                        genreId: JSON.parse(localStorage.getItem('genres'))[this.Controls.lastMenuTab].genresId
                    });
                }

                if(this.Controls.getCurrentControl() === 0){
                    this.clickOnHeader();
                }
                else if(this.Controls.getCurrentControl() === 2){
                    this.props.goToItemDetail(this.state.genresContentList[this.Controls.lastSliderActive]);
                }
                break;    
            default:
                return;
        }
    }

    /*********************************************
    * Method to get the content in the side menu *
    **********************************************/
    getGenresData = (browseToken) => {
        axios.get(env.apiUrl + urlApiCalls.genres, {
            headers: {
                browseToken: browseToken
            }
        })
        .then(response => {
            localStorage.setItem('genres', JSON.stringify(response.data.responseData));
            this._isMount && this.setState({
                genres: response.data.responseData
            })
            let genreId = JSON.parse(localStorage.getItem('genres'))[0].genresId;
            if (this.restoreScreenData.currentGenreId) {
                genreId = this.restoreScreenData.currentGenreId;
            }
            this.getGenresById(genreId);
        })
        .catch(error => {
            if(error.response.status === 401){
                ActivationService.reAuthenticate()
                    .then(() => {
                        this.getGenresData(browseToken);
                    })
                    .catch(err => {
                        console.log('reAuthenticate error:', err);
                    })
            }
        })
    }

    /***************************************************
    * Method to get the content list from genres by Id *
    ****************************************************/
    getGenresById = (genresId, isActive) => {
        axios.get(env.apiUrl + urlApiCalls.genresItem.split(':')[0] + genresId +'/', {
            headers: {
                browseToken: localStorage.getItem('browseToken')
            }
        })
        .then(response => {
            if(response.data.responseCode === 200){
                this._isMount && this.setState({
                    genresContentList: response.data.responseData.contentList,
                })
                this._isMount && this.props.toggleLoader(false);
                if(!this.firstTime){
                    this.firstTime = true;
                    if (document.getElementById('0_2')) {
                        document.getElementById('0_2').classList.add('hover-background'); 
                    }
                } 
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                ActivationService.reAuthenticate()
                    .then(() => {
                        this.getGenresById(genresId);
                    })
                    .catch(err => {
                        console.log('reAuthenticate error:', err);
                    })
            }
        })
    }

    showCarrouselItemsWithGenres = (genresContentList) => {
        return (
            genresContentList.map((elm, idx) => {
                return <ItemCarouselComponent 
                    id_control={2}
                    index={idx}
                    key={idx} 
                    item={elm} 
                    backgroundImage={env.cloudfrontUrl+elm.defaultImage+env.qualityParams}
                    goToItemDetail={this.props.goToItemDetail}
                />
            })
        ) 
    }

    render(){
        return (
            <Fragment>
                {
                    this.props.loading ? 
                        <Fragment>
                            <LoaderComponent />
                        </Fragment>
                    :
                        <div className="wrapper-content">
                            <SideMenuComponent 
                                data={this.state.genres !== null ? this.state.genres : []} 
                            />
                            <div ref={this.calcItemsPerRow} className="carousel-items-menu" id="carousel_menu">
                                {
                                    this.state.genresContentList.length !== 0 ?
                                        this.showCarrouselItemsWithGenres(this.state.genresContentList)
                                    :
                                        null
                                }
                            </div>
                        </div>
                }
                <Fragment>
                    {
                        this.state.showLoadingOverlay ?
                            <LoaderOverlayComponent /> : 
                            null
                    }
                </Fragment>
            </Fragment>
        )
    }
}

export default withRouter(GenresWrapperComponent);