import React from 'react';
import './LoaderComponent.css';

function LoaderComponent(props){
    return (
        <div className="main-wrapper-animation">
            <div className="eclipse">
                <div className="sun"></div>
                <div className="moon"></div>
            </div>
        </div>
    )
}

export default LoaderComponent;