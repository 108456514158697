import React, { Fragment } from 'react';
import LayoutComponent from '../Layout/LayoutComponent';

function ExploreComponent(props){
    return(
        <Fragment>
            <LayoutComponent page={"explore"}/>
        </Fragment>
    )
}

export default ExploreComponent;