import React from 'react';
import { withRouter } from 'react-router-dom';
import './SearchWrapperComponent.css';
import SearchFormComponent from './SearchForm/SearchFormComponent';
import axios from 'axios';
import { env, urlApiCalls, keyCode } from '../../../config';
import GeneralComponent from '../../GeneralComponent';
import ActivationService from '../../../services/ActivationService';

class SearchWrapperComponent extends GeneralComponent {

    constructor(props) {
        super(props);
        this.state = {
            filter: '',
            dataFiltered: [],
            filtered: false
        }
        this._isMount = false;
        this.idToFilterTimeout = null;
        this.prevQuery = '';
    }
    
    componentDidMount() {
        this._isMount = true;
        this.props.toggleLoader(false);
        this._isMount && setTimeout(()=> {
            this.Controls.moveDown('search');
            this.recognizeKeyDown(false);
        }, 100);

        if(!localStorage.getItem('browseToken')){
            ActivationService.getBrowseToken()
            .then(response => {
                if(response.status === 200){
                    localStorage.setItem('browseToken', response.data.browseToken);
                }
            })
        }
    }

    componentWillUnmount(){
        this.unmountListener(false);
        this._isMount = false;
        clearTimeout(this.idToFilterTimeout);
        this.props.toggleLoader(true);
    }

    setFilter = (value) => {
        if (value !== "") {
            this._isMount && this.setState({
                filter: value,
                filtered: false
            })
        }
        else {
            this._isMount && this.setState({
                filter: "",
                filtered: false
            })
        }
    }

    onSearch = () => {
        if (this.state.filter) {
            clearTimeout(this.idToFilterTimeout);
            this.idToFilterTimeout = setTimeout(() => {
                if (this.state.filter !== this.prevQuery) {
                    this.prevQuery = this.state.filter;
                    this.filterData();
                }
            }, 1200);
        }
        else {
            clearTimeout(this.idToFilterTimeout);
            this._isMount && this.setState({
                dataFiltered: [],
                filtered: false
            })
        }
    }

    filterData = () => {
        axios.get(env.apiUrl + urlApiCalls.searchContent + this.state.filter, {
            headers: {
                browseToken: localStorage.getItem('browseToken'),
            }
        })
        .then(response => {
            if (response.status === 200) {
                this._isMount && this.setState({
                    dataFiltered: response.data.responseData,
                    filtered: true
                })
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                ActivationService.reAuthenticate()
                    .then(() => {
                        this.onSearch();
                    })
                    .catch(err => {
                        console.log('reAuthenticate error:', err);
                    })
            }
        })
    }

    useControls = (keyPressed) => {

        if (keyPressed === keyCode.back && document.getElementById('controls_1') === document.activeElement) {
            return;
        }

        if (this.onBackButton(keyPressed, 'Exit')) {
            return;
        }
        
        switch(keyPressed){
            case keyCode.down:
                if(this.Controls.getCurrentControl() === 0){
                    document.getElementById('0_3').blur();
                    document.getElementById('controls_1').focus();
                    document.getElementById(this.Controls.activeSlider.toString()+'_'+this.Controls.activeElement.toString()).classList.remove('hover-background');
                    this.Controls.activeSlider += 1; 
                    this.Controls.activeElement = 0;
                }
                else if(this.Controls.getCurrentControl() === 1 && document.getElementById('controls_2')){
                    document.getElementById('controls_1').blur();
                    document.getElementById('0_3').focus();
                    this.Controls.activeSlider += 1;
                    this.Controls.activeElement = 0;
                    document.getElementById(this.Controls.activeSlider.toString()+'_'+this.Controls.activeElement.toString()).classList.add('select-item-carousel');
                }
                else if(this.Controls.getCurrentControl() === 2){
                    document.getElementById('0_3').focus();
                }
                break;
            case keyCode.up:
                if(this.Controls.getCurrentControl() === 1){
                    document.getElementById('controls_1').blur(); 
                    document.getElementById('0_3').focus();
                    this.Controls.activeSlider = 0;
                    this.Controls.activeElement = 3;   
                    document.getElementById(this.Controls.activeSlider.toString()+'_'+this.Controls.activeElement.toString()).classList.add('hover-background');
                }
                else if(this.Controls.getCurrentControl() === 2){
                    this.Controls.resetHorizontalScroll();
                    document.getElementById(this.Controls.activeSlider.toString()+'_'+this.Controls.activeElement.toString()).classList.remove('select-item-carousel');
                    document.getElementById('0_3').blur();
                    document.getElementById('controls_1').focus();
                    this.Controls.activeSlider -= 1;
                    this.Controls.activeElement = 0;
                }
                break;
            case keyCode.right:
                if(this.Controls.getCurrentControl() === 0){
                    this.Controls.moveRight('featured', 'hover-background');
                }
                else if(this.Controls.getCurrentControl() === 2){
                    this.Controls.moveRight('featured', 'select-item-carousel');    
                }
                break;
            case keyCode.left:
                if(this.Controls.getCurrentControl() === 0){
                    this.Controls.moveLeft('featured', 'hover-background');
                }
                else if(this.Controls.getCurrentControl() === 2){
                    this.Controls.moveLeft('featured', 'select-item-carousel');    
                }  
                break;
            case keyCode.enter:
                if(this.Controls.getCurrentControl() === 0){
                    this.clickOnHeader();
                }
                else if(this.Controls.getCurrentControl() === 2){
                    this.props.goToItemDetail(this.state.dataFiltered[this.Controls.getCurrentElement()]);
                }
                break;
            default:
                return;
        }
    }

    render() {
        return (
            <div className="wrapper-form">
                <SearchFormComponent
                    inputId={"controls_1"}
                    setFilter={(value) => this.setFilter(value)}
                    onSearch={() => this.onSearch()}
                    data={this.state.dataFiltered}
                    filterText={this.state.filter}
                    filtered={this.state.filtered}
                    goToItemDetail={this.props.goToItemDetail}
                />
            </div>
        )
    }
}

export default withRouter(SearchWrapperComponent);