import React, { Fragment } from 'react';
import LayoutComponent from '../../Layout/LayoutComponent';

function SerieComponent(props){
    return (
        <Fragment>
            <LayoutComponent page={"series"} item={props.location.state.data}/>
        </Fragment>
    )
}

export default SerieComponent;