import { Component } from 'react';
import { ControlService } from '../services/ControlsService';
import { urlRoutes, keyCode } from '../config';

class GeneralComponent extends Component {

    constructor(props) {
        super(props);
        this.Controls = new ControlService();
    }

    unmountListener = (addKeyDown = true) => {
        this.Controls.resetSlider();
        this.Controls.resetElement();
        // Removing the listener control before unmount
        document.removeEventListener('keyup', this.listenerControl, false);
        if (addKeyDown) {
            window.removeEventListener('keydown', this.listenerKeydown, false);
        }
    }

    listenerControl = (ev) => {
        this.useControls(ev.keyCode);
    }

    listenerKeydown = (ev) => {
        ev.preventDefault();
    }

    recognizeKeyDown = (addKeyDown = true) => {
        document.addEventListener('keyup', this.listenerControl, false);
        if (addKeyDown) {
            window.addEventListener('keydown', this.listenerKeydown, false);
        }
    }

    onBackButton = (keyPressed, action) => {
        if (keyPressed !== keyCode.back && keyPressed !== keyCode.backAlt) {
            return false;
        }

        if (action === 'GoBack') {
            this.props.history.goBack();
        } else if (action === 'Exit') {
            this.showExitDialog();
        }
        return true;
    }

    showExitDialog = () => {
        this.props.history.push(urlRoutes.exitScreen);
    }

    clickOnHeader= () => {
        switch(this.Controls.getCurrentElement()){
            case 0:
                this.Controls.pressEnter(this.props, urlRoutes.featured);
                break;
            case 1:
                this.Controls.pressEnter(this.props, urlRoutes.explore);
                break;
            case 2:
                this.Controls.pressEnter(this.props, urlRoutes.genres);
                break;
            case 3:
                this.Controls.pressEnter(this.props, urlRoutes.search);
                break;
            case 4:
                if(localStorage.getItem('authorizeToken')){
                    this.Controls.pressEnter(this.props, urlRoutes.settings);
                }
                else {
                    this.Controls.pressEnter(this.props, urlRoutes.tryfree);    
                }
                break;
            default:
                break;
        } 
    }

    hiddenMenu = () => {
        document.getElementById('controls_0').classList.remove("animation-in");
        document.getElementById('controls_0').classList.add("animation-out");
        document.getElementById('wrapper-navbar').classList.remove("animation-in");
        document.getElementById('wrapper-navbar').classList.add("animation-out");
    }

    showMenu = () => {
        document.getElementById('controls_0').classList.remove("animation-out");
        document.getElementById('controls_0').classList.add("animation-in");
        document.getElementById('wrapper-navbar').classList.remove("animation-out");
        document.getElementById('wrapper-navbar').classList.add("animation-in");
    }
}

export default GeneralComponent;