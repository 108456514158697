import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import GeneralComponent from '../../GeneralComponent';
import { env, urlApiCalls, keyCode, urlRoutes } from '../../../config';
import axios from 'axios';
import BigSliderComponent from '../../Shared/BigSlider/BigSliderComponent';
import LoaderComponent from '../../Shared/Loader/LoaderComponent';
import CarouselComponent from '../../Shared/Carousel/CarouselComponent';
import ActivationService from '../../../services/ActivationService';
import './ExploreWrapperComponent.css';
import LoaderOverlayComponent from '../../Shared/LoaderOverlay/LoaderOverlayComponent';

class ExploreWrapperComponent extends GeneralComponent {

    constructor(props){
        super(props);
        this.state = {
            categories: [],
            subCategories: [],
            currentSubCategory: null,
            showLoadingOverlay: false
        }
        this._isMount = false;

        this.idTimeoutRestoreScreen = 0;
        this.restoreScreenData = {
            backPressed: false,
            control: 0,
            element: 0,
            currentCategory: null
        }

        if (this.props.history.location.state) {
            this.restoreScreenData.control = this.props.history.location.state.currentControl;
            this.restoreScreenData.element = this.props.history.location.state.currentElement;
            this.restoreScreenData.backPressed = this.props.history.location.state.backPressed;
            this.restoreScreenData.currentCategory = this.props.history.location.state.category;
            this.state.showLoadingOverlay = true;
        } 
    }

    componentDidMount(){
        this._isMount = true;
        this.getCategories(this.handleSubCategoriesResponse);
        if (this.restoreScreenData.backPressed) {
            this.restoreScreen();
        }
    }

    componentWillUnmount(){
        this._isMount = false;
        this.props.toggleLoader(true);
        this.unmountListener();
    }

    handleSubCategoriesResponse = (element) => {
        let url = env.apiUrl+urlApiCalls.categoryItems+element.exploreId+'/';
        axios.get(url, {
            headers: {
                browseToken: localStorage.getItem('browseToken')
            }
        })
        .then(response => {
            if(response.status === 200){
                this._isMount && this.setState({
                    subCategories: response.data.responseData,
                    currentSubCategory: element
                })
            }
        })
        .catch(error => {
            if(error.response && error.response.status === 401){
                ActivationService.reAuthenticate()
                    .then(() => {
                        this.handleSubCategoriesResponse(element);
                    })
                    .catch(err => {
                        console.log('reAuthenticate error:', err); 
                    })
            }
            else {
                console.log("Error :::", error.response);
                this.props.history.push(urlRoutes.paywall);
            }
        })
    }

    getCategories = (resolve) => {
        axios.get(env.apiUrl+urlApiCalls.exploreAll, {
            headers: {
                browseToken: localStorage.getItem('browseToken')
            }
        })
        .then(response => {
            if(response.status === 200){
                this._isMount && this.props.toggleLoader(false);
                this._isMount && this.props.changeBackground({
                    featuredHeroOTT: response.data.responseData[0].defaultImage,
                    filterBlur: true
                });
                this._isMount && this.setState({
                    categories: response.data.responseData
                });
                this._isMount && setTimeout(()=> {
                    if (!this.restoreScreenData.backPressed) {
                        this.Controls.moveDown('explore', 'hover-background');
                    }
                    this.recognizeKeyDown();
                }, 10);
                let category = response.data.responseData[0];
                if (this.restoreScreenData.currentCategory) {
                    category =  response.data.responseData.find(cat => cat.exploreId === this.restoreScreenData.currentCategory);
                }
                resolve(category)
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                ActivationService.reAuthenticate()
                    .then(() => {
                        this.getCategories(this.handleSubCategoriesResponse);
                    })
                    .catch(err => {
                        console.log('reAuthenticate error:', err);
                    })
            }
        });
    }

    restoreScreen = () => {

        this.idTimeoutRestoreScreen = setInterval(() => {
            let controlVal = this.restoreScreenData.control;
            let elementVal = this.restoreScreenData.element;
            let sliderElem = document.getElementById('controls_1');
            let sliderScrolled = sliderElem ? sliderElem.classList.contains('slider-ready') : false;

            if (sliderElem && !sliderScrolled) {
                let catIndex = -1;
                this.state.categories.forEach( (cat, index)  => {
                    if (cat.exploreId === this.restoreScreenData.currentCategory) {
                        catIndex = index;
                    }
                });  
                
                if (document.getElementById('1_' + catIndex)) {
                    this.useControls(keyCode.down);
                    this.useControls(keyCode.down);
                    for (let i = 0; i < catIndex; i++) {
                        this.Controls.moveRight('explore', 'select-big-slider');
                    }
                    this.props.changeBackground({
                        featuredHeroOTT: this.state.categories[this.Controls.getLastElementActive()].defaultImage,
                        filterBlur: true
                    });
                    sliderScrolled = true;
                    sliderElem.classList.add('slider-ready');
                }
            }
            
            if (sliderScrolled) {
                if (this.Controls.getCurrentControl() !== controlVal && document.getElementById('controls_' + controlVal)) {
                    for (let i = 0; i < controlVal - 1; i++) {
                        this.useControls(keyCode.down);
                    }
                } 

                if (this.Controls.getCurrentControl() === controlVal && document.getElementById('controls_' + controlVal) &&
                    document.getElementById(controlVal + '_' + elementVal)) {
                    for (let i = 0; i < elementVal; i++) {
                        this.useControls(keyCode.right);
                    }
                    
                    this.setState({
                        showLoadingOverlay: false
                    });
                    clearInterval(this.idTimeoutRestoreScreen);
                } 
            }

        }, 100);
    }

    renderCarousels = () => {
        if(this.state.subCategories.length !== 0){
            return this.state.subCategories.map((item, idx) => {
                return(
                    <CarouselComponent 
                        key={idx}
                        items={item.exploreId !== 1 && item.contentList.length !== 0 ? item.contentList : []}
                        item={item.exploreId !== 1 && item.contentList.length !== 0 ? item : {}}
                        mainTitle={item.contentList.length !== 0 ? item.title : undefined}
                        goToItemDetail={this.props.goToItemDetail} 
                        id_control={idx+2}
                    />
                )
            })
        }
    }

    /**
     * Method to manipulate the remote control in the view
     * @description :: 
     *  this.Controls.getCurrentControl() --> current slider activate (Wrapper, slider, carousel)
     *  this.Controls.getCurrentElement() --> current item inside a slider/wrapper/carousel
     */
    useControls = (keyPressed) => {

        if (this.onBackButton(keyPressed, 'Exit')) {
            return;
        }
        
        switch(keyPressed){
            case keyCode.down:
                if(this.Controls.getCurrentControl() === -1) {
                    this.Controls.moveDown('explore', 'active-tab');
                }
                else if(this.Controls.getCurrentControl() === 0){
                    this.Controls.moveDown('explore', 'select-big-slider', 'hover-background');
                    this.hiddenMenu();
                }
                else if(this.Controls.getCurrentControl() === 1){
                    document.getElementsByClassName('main-wrapper')[0].style.overflow = "unset";
                    this.Controls.moveDown('explore', 'select-item-carousel', 'select-big-slider');    
                }
                else {
                    this.Controls.moveDown('explore', 'select-item-carousel'); 
                }
                break;
            case keyCode.up: 
                if(this.Controls.getCurrentControl() === -1) {
                    this.Controls.moveUp('explore', 'active-tab');
                }
                else if(this.Controls.getCurrentControl() === 0){
                    return;
                }
                else if(this.Controls.getCurrentControl() === 1){
                    this.Controls.moveUp('explore', 'hover-background', 'select-big-slider');
                    this.showMenu();
                }
                else if(this.Controls.getCurrentControl() === 2){
                    document.getElementsByClassName('main-wrapper')[0].style.overflow = "hidden";
                    this.Controls.moveUp('explore', 'select-big-slider' ,'select-item-carousel'); 
                }
                else {
                    this.Controls.moveUp('explore', 'select-item-carousel');
                }
                break;    
            case keyCode.right:
                if(this.Controls.getCurrentControl() === 0){
                    if(this.Controls.getCurrentElement() === 0){
                        document.getElementById(this.Controls.getCurrentControl().toString()+'_1').children[0].classList.remove('active-tab'); 
                    }
                    else if(this.Controls.getCurrentElement() === 1){
                        document.getElementById(this.Controls.getCurrentControl().toString()+'_1').classList.remove('hover-background');
                        document.getElementById(this.Controls.getCurrentControl().toString()+'_1').children[0].classList.add('active-tab');
                    }
                    else if(this.Controls.getCurrentElement() >= 1){
                        document.getElementById(this.Controls.getCurrentControl().toString()+'_1').children[0].classList.add('active-tab');
                    }
                    this.Controls.moveRight('explore', 'hover-background'); 
                }
                else if(this.Controls.getCurrentControl() === 1){
                    this.Controls.moveRight('explore', 'select-big-slider');
                    this.handleSubCategoriesResponse(this.state.categories[this.Controls.getLastElementActive()]);
                    this.props.changeBackground({
                        featuredHeroOTT: this.state.categories[this.Controls.getLastElementActive()].defaultImage,
                        filterBlur: true
                    });
                }
                else {
                    this.Controls.moveRight('explore', 'select-item-carousel');    
                }
                break;
            case keyCode.left:
                if(this.Controls.getCurrentControl() === 0){
                    if(this.Controls.getCurrentElement() === 2){
                        document.getElementById(this.Controls.getCurrentControl().toString()+'_1').children[0].classList.remove('active-tab'); 
                    }
                    else {
                        document.getElementById(this.Controls.getCurrentControl().toString()+'_1').children[0].classList.add('active-tab');    
                    }
                    this.Controls.moveLeft('explore', 'hover-background');  
                }
                else if(this.Controls.getCurrentControl() === 1){
                    this.Controls.moveLeft('explore', 'select-big-slider');
                    this.handleSubCategoriesResponse(this.state.categories[this.Controls.getLastElementActive()]);
                    this.props.changeBackground({
                        featuredHeroOTT: this.state.categories[this.Controls.getLastElementActive()].defaultImage,
                        filterBlur: true
                    });
                }
                else {
                    this.Controls.moveLeft('explore', 'select-item-carousel');    
                }
                break;
            case keyCode.enter: 
                if (this.Controls.getCurrentControl() > 1){
                    this.props.history.replace('/explore', {
                        currentControl: this.Controls.getCurrentControl(), 
                        currentElement: this.Controls.getCurrentElement(),
                        backPressed: true,
                        category: this.state.currentSubCategory.exploreId
                    });
                }

                if(this.Controls.getCurrentControl() === 0){
                    this.clickOnHeader();
                }
                else if(this.Controls.getCurrentControl() > 1){
                    this.props.goToItemDetail(this.state.subCategories[this.Controls.getCurrentControl() - 2].contentList[this.Controls.getCurrentElement()]);
                }
                break;
            default:
                return;
        }
    }

    render(){
        return(
            <Fragment>
                {
                    this.props.loading ? 
                        <LoaderComponent />
                    :
                        <div className="main-wrapper">
                            <BigSliderComponent 
                                items={this.state.categories.length !== 0 ? this.state.categories : []}
                                hoveredElement={this.hoveredElement}
                            />
                            <div className="wrapper-explore-carousels">
                                {this.renderCarousels()}
                            </div>
                        </div>
                }
                <Fragment>
                    {
                        this.state.showLoadingOverlay ?
                            <LoaderOverlayComponent /> : 
                            null
                    }
                </Fragment>
            </Fragment>
        )
    }

}

export default withRouter(ExploreWrapperComponent);