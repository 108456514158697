import React from 'react';
import 'shaka-player/dist/controls.css';
const shaka = require('shaka-player/dist/shaka-player.ui.js');

// Adaptative stream Transmuxing
import('mux.js').then(function (muxjs) { window.muxjs = muxjs.default; }).catch(console.error);

class ShakaPlayer extends React.PureComponent{

    player = null;
    video = null;
    videoContainer = null;
    
    constructor(props){

        super(props);

        //Creating reference to store video component on DOM
        this.videoComponent = React.createRef();

        //Creating reference to store video container on DOM
        this.videoContainer = React.createRef();
        
        //Initializing reference to error handlers
        this.onErrorEvent = this.onErrorEvent.bind(this);
        this.onError = this.onError.bind(this);
    }
    
   componentDidMount(){
        // Check to see if the browser supports the basic APIs Shaka needs.
        if (!shaka.Player.isBrowserSupported()) {   
            console.error('Browser not supported!');
        }else{
             
            //Getting reference to video and video container on DOM
            this.video = this.videoComponent.current;

            //Initialize shaka player
            this.player = new shaka.Player(this.video);
            //this.player.configure('manifest.defaultPresentationDelay', 0);
            
            this.player.configure({
                preferredTextLanguage: 'eng',
                preferredAudioLanguage: 'eng',                
                streaming: {
                  forceTransmuxTS: true
                }
            });
  
            // Load browser compability polyfill
            shaka.polyfill.installAll();

            // Try to load a manifest.
            this.player.load(this.props.url, this.props.start)
                .then(() => this.init())              
                .catch(this.onError);  // onError is executed if the asynchronous load fails.
        }
    }
    
    init = () => {
        // Listen for events.
        this.addListeners();
        
        // Render UI controls
        this.renderControls();
        
        // Load captions
        this.loadCaptions();
    }
    
    componentWillUnmount () {
      this.removeListeners();
    }
       
    addListeners () {
      const { onReady, onPlay, onBuffer, onPlaying, onPause, onSeek, onEnded, onError, onProgress } = this.props;
      this.video.addEventListener('canplay', onReady);
      this.video.addEventListener('play', onPlay);
      this.video.addEventListener('waiting', onBuffer);
      this.video.addEventListener('playing', onPlaying);
      this.video.addEventListener('pause', onPause);
      this.video.addEventListener('seeked', onSeek);
      this.video.addEventListener('ended', onEnded);
      this.video.addEventListener('error', onError);
      this.video.addEventListener('progress', onProgress);
    }
    removeListeners () {
      const { onReady, onPlay, onBuffer, onPlaying, onPause, onSeek, onEnded, onError, onProgress} = this.props;
      this.video.removeEventListener('canplay', onReady);
      this.video.removeEventListener('play', onPlay);
      this.video.removeEventListener('waiting', onBuffer);
      this.video.removeEventListener('playing', onPlaying);
      this.video.removeEventListener('pause', onPause);
      this.video.removeEventListener('seeked', onSeek);
      this.video.removeEventListener('ended', onEnded);
      this.video.removeEventListener('error', onError);
      this.video.removeEventListener('progress', onProgress);
    }
    
    renderControls(){
        // Show controls UI for other players non tizen
        if(typeof window.tizen === 'undefined'){
            //Setting UI configuration JSON object
            const uiConfig = {};

            //Configuring elements to be displayed on video player control panel
            uiConfig['controlPanelElements'] = ['mute', 'volume', 'time_and_duration', 'fullscreen', 'overflow_menu', ];
            //uiConfig['controlPanelElements'] = ['overflow_menu'];

            //Setting up shaka player UI
            const ui = new shaka.ui.Overlay(this.player, this.videoContainer.current, this.video);

            ui.configure(uiConfig); //configure UI
            ui.getControls();        
        }        
    }
    
    loadCaptions(){
        let captions = JSON.parse(this.props.captions);
        let isocodes = {"en" : "eng", "es" : "spa", "fr" : "fre", "pt" : "por", "de" : "deu"};
        
        captions.forEach(function(item){
            this.player.addTextTrack(item.file, isocodes[item.language], 'subtitle', 'text/vtt', '', item.label);
        }, this);

        //this.player.setTextTrackVisibility(undefined);
        //this.player.setTextTrackVisibility(true);
    }
    
    play () {
      const promise = this.video.play();
      if (promise) {
        promise.catch(this.props.onError);
      }
    }
    pause () {
      this.video.pause();
    }  
    stop () {
      this.video.removeAttribute('src');
    }
    seekTo (seconds) {
      this.video.currentTime = seconds;
    }
    setVolume (fraction) {
      this.video.volume = fraction;
    }
    mute = () => {
      this.video.muted = true;
    }
    unmute = () => {
      this.video.muted = false;
    }    
    
    setPlaybackRate (rate) {
      this.video.playbackRate = rate;
    }    
    
    getDuration () {
      if (!this.video) return null;
      const { duration, seekable } = this.video;
      // on iOS, live streams return Infinity for the duration
      // so instead we use the end of the seekable timerange
      if (duration === Infinity && seekable.length > 0) {
        return seekable.end(seekable.length - 1);
      }
      return duration;
    }
    
    getCurrentTime () {
      if (!this.video) return null;
      return this.video.currentTime;
    }
    
    getSecondsLoaded () {
      if (!this.video) return null;
      const { buffered } = this.video;
      if (buffered.length === 0) {
        return 0;
      }
      const end = buffered.end(buffered.length - 1);
      const duration = this.getDuration();
      if (end > duration) {
        return duration;
      }
      return end;
    }
    
    onErrorEvent(event) {
      // Extract the shaka.util.Error object from the event.
      this.onError(event.detail);
    }

    onError(error) {
      // Log the error.
      console.error('Error code', error.code, 'object', error);
    }
    
    render(){
            const style = {
              width: this.props.width,
              height: this.props.height
            };
            return(
                <div className="video-container" ref={this.videoContainer}>
                    <video 
                        className="shaka-video"
                        ref={this.videoComponent}
                        style={style}
                        preload='auto'
                        autoPlay        
                    />
                </div>                    
            );
    }
}

ShakaPlayer.defaultProps = {
  start: 0,
  url: '',
  width: '100%',
  height: '100%',
  captions : {}
};

export default ShakaPlayer;