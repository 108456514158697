import React from 'react';
import { withRouter } from 'react-router-dom';
import './DeviceSignupComponent.css';
import { ActivationService } from '../../services/ActivationService';
import GeneralComponent from '../GeneralComponent';
import { keyCode, env, urlRoutes } from '../../config';

class DeviceSignupComponent extends GeneralComponent {

    constructor(props) {
        super(props);
        this.state = {
            codeActivation: ""
        }
        this.intervalId = '';
        this._isMount = false;
    }

    componentDidMount() {
        this._isMount = true;
        this.recognizeKeyDown();
        this.handleDeviceLinking()
        // ActivationService.getDeviceLinkingToken(this.handleDeviceLinking);
    }

    componentWillUnmount(){
        this.cleanEvents();
        this.unmountListener();
    }

    cleanEvents = () => {
        this._isMount = false;
        clearInterval(this.intervalId);
        localStorage.removeItem('regcode');
    }

    goToItemDetail = (item) => {
        let keys = Object.keys(item)[0];
        switch(keys){
            case 'seriesId':
                this.props.history.push({
                    pathname: "/series/"+item.seriesId,
                    state: { data: item }
                });
                break;
            case 'videoId': 
                this.props.history.push({
                    pathname: "/video/"+item.videoId,
                    state: { data: item }
                });
                break;
            case 'playlistId':
                this.props.history.push({
                    pathname: "/playlist/"+item.playlistId,
                    state: { data: item }
                });
                break;        
            default:
                return;
        }  
    }

    useControls = (keyPressed) => {

        if (this.onBackButton(keyPressed, 'GoBack')) {
            this.cleanEvents(); 
            return;
        }

        switch(keyPressed){
            case keyCode.enter:
                if(this.props.location.state.url){
                    this.goToItemDetail(this.props.location.state.video);
                } 
                break;   
            default: 
                return;
        }
    }

    handleActivationCode = (activationCode) => {
        this._isMount && this.setState({
            codeActivation: activationCode.toString()
        })
        localStorage.setItem('regcode',activationCode.toString());
        this.intervalId = setInterval(()=> {
            ActivationService.verifyActivationCode(localStorage.getItem('regcode'), this.handleVerificationCode);
        }, 2000);
    }

    handleDeviceLinking = (response) => {
        // localStorage.setItem('token',response.data.token.toString());
        ActivationService.getActivationCode(this.handleActivationCode);
    }

    handleVerificationCode = (response) => {
        // The code was verified by the API, I should activate
        if(response.data.linkStatus){
            localStorage.setItem('authorizeToken',response.data.userData.authorizeToken);
            localStorage.setItem('deviceToken',response.data.userData.deviceToken);
            localStorage.setItem('refreshToken', response.data.userData.refreshToken);
            localStorage.removeItem('regcode');
            this.props.history.push(urlRoutes.featured);
        }
    }

    render() {
        return (
            <div className="wrapper-device">
                <div className="main-logo-box"></div>
                <div className="wrapper-text">
                    <div className="logo-magellan"></div>
                    {
                        this.props.location.state && this.props.location.state.url ?
                            <div className="preview-title">
                                Your 5 minute preview has ended.
                            </div>
                        : null
                    }
                    <div className="title-tryfree">
                        TRY IT FOR FREE
                    </div>
                    <div className="from-pc-title">
                        From your PC, phone, or tablet go to:
                    </div>
                    <div className="link-magellan">
                        {env.linkMagellan}
                    </div>
                    <div className="code-title">
                        Then enter this code:
                    </div>
                    <div className="code-obtained">
                        {this.state.codeActivation}
                    </div>
                    {
                        this.props.location.state && this.props.location.state.url ?
                            <div className="button-keep-browsing">
                                KEEP BROWSING
                            </div>
                        : null
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(DeviceSignupComponent);