import { keyCode } from '../../config';

export class SamsungRemote {
    
    /**
     * Register keymap for tizen remote devices
     */
    static registerKeyCodes(){
        
        let tizen = window.tizen;
        tizen.tvinputdevice.registerKeyBatch([
            'MediaPlayPause', 
            'MediaPlay',
            'MediaPause',
            'MediaStop',
            'MediaFastForward',
            'MediaRewind',
            'Caption',
            'Exit'
        ]);
    }
    
    /**
     * Unegister keymap for tizen remote devices
     */
    static unregisterKeyCodes(){
        // TODO extra code is needed ?
    }
    
    /**
     * Call events from component once a key is pressed
     * @param int keyPressed
     * @param React.Ref component
     */
    static useControls = (keyPressed, component) => {
       
        let tizen = window.tizen;
        
        switch(keyPressed){
            case tizen.tvinputdevice.getKey('MediaPlayPause').code:
                component.handlePlayPause();
                break;
            case tizen.tvinputdevice.getKey('MediaPlay').code:
                component.handlePlay();
                break;
            case tizen.tvinputdevice.getKey('MediaPause').code:
                component.handlePause();
                break;
            case tizen.tvinputdevice.getKey('MediaStop').code:
                component.handleStop();
                component.props.history.goBack();
                break;
            case keyCode.back:
                component.props.history.goBack();
                break;
            case tizen.tvinputdevice.getKey('MediaFastFoward').code:
                component.handleFastForwardVideo();
                break;
            case tizen.tvinputdevice.getKey('MediaRewind').code:
                component.handleRewind();
                break;
            case tizen.tvinputdevice.getKey('Caption').code:
                // TODO open caption settings 3 Phase
                break;
            case tizen.tvinputdevice.getKey('Exit').code:
                tizen.application.getCurrentApplication().exit();
                break;
            default:
                return;
        }
    }
}

export default SamsungRemote;