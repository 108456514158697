import React, { Component } from 'react';
import './BrowseComponent.css';
import { env } from '../../../config';
import NavbarContentComponent from '../ContentNavbar/NavbarContentComponent';
import { withRouter } from 'react-router-dom';

class BrowseComponent extends Component {

    constructor(props) {
        super(props);
        this._isMount = false;
    }

    componentDidMount(){
        this._isMount = true;
    }

    render() {
        return (
            <div id="main-wrapper-header" className={"flex-wrapper"}>
                <div style={{position: 'absolute', zIndex: 0, marginTop: 30}}>
                    <img
                        src={env.bucketS3+"images/onboarding/logo-magellan-medium.svg"}
                        alt="Magellan logo"
                        className="image-magellan-logo"
                        width="160"
                        height="160"
                    />
                </div>
                <div id="wrapper-navbar" className={"wrapper-nav"}>
                    <NavbarContentComponent
                        goToSearch={this.goToSearch}
                        allIds={{
                            featured: "0_0",
                            explore: "0_1",
                            genres: "0_2",
                            search: "0_3",
                            tryfree: !localStorage.getItem('authorizeToken') ? "0_4" : "",
                            settings: localStorage.getItem('authorizeToken') ? "0_4" : ""
                        }}
                    />
                </div>
            </div>
        )
    }

}

export default withRouter(BrowseComponent);