
/**
* Variable to place all the url's used in whole app
*/
export const env = {
    smartTvBucket: "https://magellantv-smart-tv-assets.s3.amazonaws.com/",
    bucketS3: "https://magellantv-test-cloudfront.s3.amazonaws.com/",
    cloudfrontUrl: "https://vewd.magellantv.com/",
    devCloudFrontUrl: "https://vewd.magellantv.com/",
    devUpdatingVideo: "http://ec2-18-234-81-233.compute-1.amazonaws.com/update",
    prodUpdatingVideo: "http://ec2-18-234-46-144.compute-1.amazonaws.com/update",
    apiUrl: "https://www.magellantv.com/api/v4",
    linkMagellan: "https://www.magellantv.com/vewd",
    qualityParams: "?width=722&height=406&quality=90&jpg=true",
    qualityTitleImage: "?width=528&height=297&quality=90",
    qualityBigSlider: "?width=1280&height=720&quality=90&jpg=true",
    defaultPlayer : 'vewd'
}

/*export const apiDevKeys = {
    "app": {
        "name": "test_app",
        "key": "P7nKDxpgBtx4k",
        "secret": "xsYEI1kg6tTAUgw1Pstx6z15"
    }
}*/

export const apiDevKeys = {
    "app": {
		"name": "magellantv.com",
		"key": "jZXxl4DZzHND2",
		"secret": "E2Ic1Kz31ppohxMXDXQ9VG4d"
	}
}

export const urlApiCalls = {
    videos: "/video/",
    series: "/series/",
    seriesepisodes: "/seriesepisode/:idserie/:idepisode",
    playlists: "/playlist/",
    featured: "/featured",
    exploreAll: "/explorelander/",
    categoryItems: "/exploresublander/",
    genres: "/genres/",
    genresItem: "/genres/:id/",
    searchContent: "/searchcontent/",
    signIn: "/signin",
    browseToken: "/fireTvlogin/",
    activationCode: "/devicelinking/regcode?userData=",
    tokenDeviceLinking: "/devicelinking/token",
    statusDeviceLinking: "/devicelinking/status/",
    revoke: "/revoke",
    resumeOnComplete: "/resumeOnComplete",
    resume: "/resume"
};

export const urlRoutes = {
    paywall: "/paywall",
    login: "/login",
    featured: "/featured",
    series: '/series/:id',
    videos: '/video/:id',
    playlist: '/playlist/:id',
    explore: "/explore",
    search: "/search",
    deviceSignUp: "/device-link-sign-up",
    deviceSignIn: "/device-link-sign-in",
    tryfree: "/device-link-sign-up",
    settings: "/settings",
    genres: "/genres",
    exitScreen: "/exit-screen",
    videoPlayer: "/videoplayer"
}

export const keyCode = {
    left: 37,
    right: 39,
    enter: 13,
    up: 38,
    down: 40,
    back: 8,
    backAlt: 461,
    escape: 27,
    play: 415, // Used in VEWD platform
    pause: 19,
    rewind: 412, // Used in VEWD platform
    fastForward: 417, // Used in VEWD platform,
    menu: 462, // Used in VEWD platform
    stop: 413,
}

export const genresConf = {
    itemWidth: 369
}