import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import GeneralComponent from '../../GeneralComponent';
import { env, urlApiCalls, keyCode, urlRoutes } from '../../../config';
import axios from 'axios';
import CarouselComponent from '../../Shared/Carousel/CarouselComponent';
import './FeaturedWrapperComponent.css';
import DotsWrapper from '../../Shared/Dots/DotsWrapper';
import LoaderComponent from '../../Shared/Loader/LoaderComponent';
import ActivationService from '../../../services/ActivationService';
import ImageItemCarouselComponent from '../../Shared/ImageItemCarousel/ImageItemCarouselComponent';
import LoaderOverlayComponent from '../../Shared/LoaderOverlay/LoaderOverlayComponent';

/**
 * Featured page component
 * show big slider of images
 * and show N carrousels of image sections
 */
class FeaturedWrapperComponent extends GeneralComponent {

    constructor(props) {
        super(props);
        this.state = {
            sliders: [],
            mainFeatureSlider: [],
            documentaries: [],
            dotActive: 0,
            idxSliderActive: 0,
            imagesLoaded: 0,
            isSliderActive: true,
            showLoadingOverlay: false
        }
        this.countCycle = 0;
        this.idTimeout = 0;
        this.idTimeoutRestoreScreen = 0;
        this._isMount = false;
        this.currentControl = 0;
        this.currentElement = 0;

        this.restoreScreenData = {
            backPressed: false,
            control: 0,
            element: 0
        }
        if (this.props.history.location.state) {
            this.restoreScreenData.control = this.props.history.location.state.currentControl;
            this.restoreScreenData.element = this.props.history.location.state.currentElement;
            this.restoreScreenData.backPressed = this.props.history.location.state.backPressed;
            this.state.showLoadingOverlay = true;
        } 
    }

    componentDidMount() {
        this._isMount = true;

        if ( !localStorage.getItem('authorizeToken') ){
            ActivationService.getBrowseToken()
            .then(response => {
                localStorage.setItem('browseToken',response.data.browseToken);
                this.getAllDocumentaries(response.data.browseToken);
            })
            .catch(error => {
                console.log("No browse token generated");
            })
        }
        else {
            localStorage.removeItem('regcode');
            this.getAllDocumentaries();
        }
    }

    componentWillUnmount(){
        this._isMount = false;
        this.unmountListener();
        clearInterval(this.idTimeoutRestoreScreen);
    }

    /**
     * Method to get all the documentaries
     * @param :: browseToken --> Token received if the user is not logged in
     */
    getAllDocumentaries = (browseToken) => {
        let headers = {};
        if (browseToken){
            headers.browseToken = localStorage.getItem('browseToken');
        }
        else {
            headers.authorization = localStorage.getItem('authorizeToken');
            headers.deviceToken = localStorage.getItem('deviceToken');    
        }

        axios.get(env.apiUrl + urlApiCalls.featured, { headers })
        .then(response => {
            if (response.status === 200) {
                this._isMount && this.props.changeTitleImage(response.data.responseData[0]['contentList'][0]);
                this._isMount && this.setState({
                    sliders: response.data.responseData[0]['contentList'],
                    documentaries: response.data.responseData.filter((elm,idx) => elm.featureId !== 1 && elm.featureId !== 61)
                });
                this._isMount && setTimeout(()=> {
                    this.Controls.moveDown('featured', 'hover-background');
                    this.recognizeKeyDown();
                }, 100);
                this.props.toggleLoader(false);
                this.restoreScreen();
                this._isMount && this.props.changeBackground(response.data.responseData[0]['contentList'][0]);
                this.autoPlaySlider(response.data.responseData[0]['contentList']);
            }
        })
        .catch(error => {
            if(error.response.status === 401) {
                ActivationService.reAuthenticate()
                    .then(() => {
                        this.getAllDocumentaries(browseToken);
                    })
                    .catch(err => {
                        console.log('reAuthenticate error:', err);
                    })
            } else {
                this.props.history.push(urlRoutes.paywall);
            }
        })
    }

    restoreScreen = () => {

        if (!this.restoreScreenData.backPressed) {
            return;
        }

        this.idTimeoutRestoreScreen = setInterval(() => {
            let controlVal = this.restoreScreenData.control;
            let elementVal = this.restoreScreenData.element;
            
            if (this.Controls.getCurrentControl() !== controlVal && document.getElementById('controls_' + controlVal)) {
                for (let i = 0; i < controlVal; i++) {
                    this.useControls(keyCode.down);
                }
            } 

            let elementSelected = false;
            if (controlVal !== 0 && this.Controls.getCurrentControl() === controlVal && document.getElementById('controls_' + controlVal) &&
                document.getElementById(controlVal + '_' + elementVal)) {
                for (let i = 0; i < elementVal; i++) {
                    this.useControls(keyCode.right);
                }
                elementSelected = true; 
            } 

            if (elementSelected || controlVal === 0) {
                this.setState({
                    showLoadingOverlay: false
                });
                clearInterval(this.idTimeoutRestoreScreen);  
            }

        }, 10);

        
    }

    /** 
     * Method to autoplay the slider in recursive way without overflow. 
     * @param sliders: the array of slider items
     * @param countCycle :: Variable to keep tracked the slider is going to be shown.
     */
    autoPlaySlider = (sliders) => {
        if(this.state.isSliderActive){
            this.countCycle = this.Controls.lastSliderActive;
            
            this.idTimeout = setTimeout(() => {
                if(this.countCycle <= sliders.length - 1){
                    if (document.getElementById('image-title-container')) {
                        if(this.countCycle === document.getElementById('image-title-container').childElementCount - 1){
                            this.Controls.lastPixelsMoved = 0;
                            this.Controls.lastSliderActive = 0;
                            this.countCycle = 0;
                        }
                        else {
                            this.Controls.lastPixelsMoved += 4382;
                            this.Controls.plusSliderActive();
                            this.countCycle += 1;
                        }

                        let idElement = "image-title-container";
                        document.getElementById(idElement).style.position = 'relative';
                        document.getElementById(idElement).style.right = this.Controls.lastPixelsMoved !== 0 ? 
                        this.Controls.lastPixelsMoved.toString()+'px' : "0px"; 
                        this.handleDotClick(this.countCycle);
                    }

                    this.autoPlaySlider(sliders);
                }
            }, 7000);
        }
        else {
            clearTimeout(this.idTimeout);
        }
    }

    renderCarousels = () => {
        if(this.state.documentaries.length !== 0){
            let id_control = 1;
            return this.state.documentaries.map((item, idx) => {
                if (item.title === "New Releases"){
                    if(this.state.documentaries[0].contentList.length !== 0){
                        id_control = 3;
                    }
                    else {
                        id_control = 2;
                    }
                }
                else {
                    id_control += 1;
                }
                return(
                    <CarouselComponent 
                        id_control={id_control}
                        key={idx}
                        items={item.featuredId !== 1 && item.featureId !== 61 && item.contentList.length !== 0 ? item.contentList : []}
                        item={item.featuredId !== 1 && item.featureId !== 61 && item.contentList.length !== 0 ? item : {}}
                        mainTitle={item.contentList.length !== 0 ? item.title : undefined}
                        goToItemDetail={this.props.goToItemDetail} 
                    />
                )
            })
        }
    }

    /**
     * Method to active a specific dot
     * @param :: idx --> id dots array
     */
    handleDotClick = (idx) => {
        this._isMount && this.setState({
            dotActive: idx
        });
        this._isMount && this.props.changeBackground(this.state.sliders[idx]);
    }

    /**
     * Method to show the small images in sliders (Title Images)
     */
    renderImagesTitle = () => {
        if(this.state.sliders.length !== 0){
            return this.state.sliders.map((elm, idx) => {
                return (
                    <ImageItemCarouselComponent
                        id={idx}
                        key={idx} 
                        titleImage={
                            elm.featuredTitleImage 
                            ? env.cloudfrontUrl + elm.featuredTitleImage + env.qualityTitleImage 
                            : elm.featuredTagline 
                                ? env.cloudfrontUrl + elm.defaultImage + env.qualityTitleImage
                                : ""
                        }
                        featuredTagline={elm.featuredTagline}
                    />
                )
            })
        }    
    }

    useControls = (keyPressed) => {

        console.log('key: ' + keyPressed);
        if (this.onBackButton(keyPressed, 'Exit')) {
            return;
        }

        switch(keyPressed){
            case keyCode.down: // Arrow down
                if(this.Controls.getCurrentControl() === -1) {
                    this.Controls.moveDown('featured', 'active-tab');
                }
                else if(this.Controls.getCurrentControl() === 0){
                    document.getElementsByClassName('watch-button')[0].classList.add('watch-button-active');
                    this.Controls.moveDown('featured', 'watch-button-active', 'hover-background');
                    this._isMount && this.setState({
                        isSliderActive: false
                    });
                    clearTimeout(this.idTimeout);
                    this.countCycle = this.lastSliderActive;
                    this.hiddenMenu();
                }
                else if(this.Controls.getCurrentControl() === 1) {
                    document.getElementsByClassName('main-wrapper')[0].style.overflow = "unset";
                    this.Controls.moveDown('featured', 'select-item-carousel', 'watch-button-active');
                    this.props.changeBackground({featuredHeroWeb: 'home-bg.jpg', isFeatured: true});
                }
                else {
                    this.Controls.moveDown('featured', 'select-item-carousel');    
                }
                break;
            case keyCode.up: // Arrow Up
                if(this.Controls.getCurrentControl() === -1) {
                    this.Controls.moveUp('featured', 'active-tab');
                }
                else if(this.Controls.getCurrentControl() === 0){
                    return;
                }
                else if(this.Controls.getCurrentControl() === 1){
                    this.Controls.moveUp('featured', 'hover-background', 'watch-button-active');
                    this.showMenu();
                    this._isMount && this.setState({
                        isSliderActive: true
                    });
                    setTimeout(() => {
                        this.autoPlaySlider(this.state.sliders);
                    }, 20);
                }
                else if(this.Controls.getCurrentControl() === 2){   
                    document.getElementsByClassName('main-wrapper')[0].style.overflow = "hidden";
                    this.Controls.moveUp('featured', 'watch-button-active', 'select-item-carousel'); 
                    this.props.changeBackground(this.state.sliders[this.Controls.getCurrentElement()]);   
                }
                else {
                    this.Controls.moveUp('featured', 'select-item-carousel');     
                }
                break;
            case keyCode.right: // Arrow Right
                if(this.Controls.getCurrentControl() === 0){
                    if (this.Controls.getCurrentElement() === 0){
                        document.getElementById(this.Controls.getCurrentControl().toString()+'_0').children[0].classList.remove('hover-background');
                        document.getElementById(this.Controls.getCurrentControl().toString()+'_0').children[0].classList.add('active-tab'); 
                    }
                    if(!document.getElementsByClassName('active-tab')[0]){
                        document.getElementById(this.Controls.getCurrentControl().toString()+'_0').children[0].classList.add('active-tab');
                    }   
                    this.Controls.moveRight('featured', 'hover-background');
                }
                else if(this.Controls.getCurrentControl() === 1){
                    this.Controls.moveRight('featured', 'active-dot');
                    this.handleDotClick(this.Controls.getCurrentElement());
                }
                else if(this.Controls.getCurrentControl() > 1) {
                    this.Controls.moveRight('featured', 'select-item-carousel');    
                }
                break;
            case keyCode.left: // Arrow Left
                if(this.Controls.getCurrentControl() === 0){
                    if(this.Controls.getCurrentElement() === 1){
                        document.getElementById(this.Controls.getCurrentControl().toString()+'_0').children[0].classList.remove('active-tab');
                        this.Controls.moveLeft('featured', 'hover-background');  
                    }
                    else {
                        this.Controls.moveLeft('featured', 'hover-background');
                    }
                }
                if(this.Controls.getCurrentControl() === 1){
                    this.Controls.moveLeft('featured', 'active-dot');
                    this.props.changeTitleImage(this.state.sliders[this.Controls.getCurrentElement()]);
                    this.handleDotClick(this.Controls.getCurrentElement());
                }
                else if(this.Controls.getCurrentControl() > 1) {
                    this.Controls.moveLeft('featured', 'select-item-carousel');    
                }
                break;
            case keyCode.enter: // Enter key from remote control
                this.props.history.replace('/featured', {
                    currentControl: this.Controls.getCurrentControl(), 
                    currentElement: this.Controls.getCurrentElement(),
                    backPressed: true
                });
                clearTimeout(this.idTimeout);
                if(this.Controls.getCurrentControl() === 0){
                    this.clickOnHeader();
                }
                else if(this.Controls.getCurrentControl() === 1){
                    this.props.goToItemDetail(this.state.sliders[this.state.dotActive]);
                }
                else{
                    if(this.state.documentaries[0].contentList.length === 0){
                        this.props.goToItemDetail(this.state.documentaries[this.Controls.getCurrentControl()-1].contentList[this.Controls.getCurrentElement()]);
                    }
                    else {
                        this.props.goToItemDetail(this.state.documentaries[this.Controls.getCurrentControl()-2].contentList[this.Controls.getCurrentElement()]);    
                    }
                }    
                break;
            default:
                return;
        }
    }

    render() {
        return (
            <Fragment>
                {
                    this.props.loading ? 
                        <LoaderComponent />   
                    :
                    <div className="main-wrapper">
                        {
                            this.state.documentaries.length !== 0 && !this.props.loading ?
                                <Fragment>
                                    <div className="wrapper-slider">
                                        <div id={"image-title-container"} className="wrapper-image-title">
                                            { this.renderImagesTitle() }
                                        </div>
                                        <div id="watch-button-wrap" className="watch-button">
                                            <div>
                                                WATCH
                                            </div>
                                        </div>
                                        <DotsWrapper 
                                            index={this.state.dotActive} 
                                            dots={
                                                this.state.sliders.length !== 0 
                                                ? this.state.sliders 
                                                : 0 
                                            }
                                            dotClick={this.handleDotClick}
                                        />
                                    </div>
                                    {this.renderCarousels()}
                                </Fragment>
                            : null
                        }
                    </div>
                }
                <Fragment>
                    {
                        this.state.showLoadingOverlay ?
                            <LoaderOverlayComponent /> : 
                            null
                    }
                </Fragment>
            </Fragment>
        )
    }
}

export default withRouter(FeaturedWrapperComponent);