import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import GeneralComponent from '../../GeneralComponent';
import '../ItemDetailComponent.css';
import { env, urlApiCalls, keyCode, urlRoutes } from '../../../config';
import axios from 'axios';
import ItemDetailComponent from '../ItemDetailComponent';
import CarouselComponent from '../../Shared/Carousel/CarouselComponent';
import LoaderComponent from '../../Shared/Loader/LoaderComponent';
import ActivationService from '../../../services/ActivationService';

class VideoWrapperComponent extends GeneralComponent {

    constructor(props){
        super(props);
        this.state = {
            relatedContent: [],
            currentElement: null,
            activeCarousel: false
        }
        this._isMount = false;
    }

    componentDidMount() {
        this.unmountListener();
        this._isMount = true;
        this.getVideoById(this.props.location.state.data);
    }

    componentDidUpdate(prevProps){
        // The route is the same and it doesn't change so I should ask to re render
        if(prevProps.location.pathname.split('/')[2] !== this.props.location.pathname.split('/')[2]){
            this.props.toggleLoader(true);
            this.getVideoById(this.props.location.state.data);
        }
    }

    componentWillUnmount(){
        this._isMount = false;
        this.unmountListener();
        this.props.toggleLoader(true);
    }

    getVideoById = (video) => {
        let headers = {}

        if(localStorage.getItem('authorizeToken')){
            headers.authorization = localStorage.getItem('authorizeToken');
            headers.deviceToken = localStorage.getItem('deviceToken');
        }
        else {
            headers.browseToken = localStorage.getItem('browseToken');  
        }

        axios.get(env.apiUrl+urlApiCalls.videos+video.videoId+'/', { headers })
        .then(response => {
            if(response.status === 200){
                this._isMount && this.props.toggleLoader(false);
                this._isMount && this.props.changeBackground({
                    featuredHeroOTT: video.featuredHeroOTT   
                    ? video.featuredHeroOTT
                    : video.defaultImage
                });
                this._isMount && this.setState({
                    relatedContent: response.data.responseData.relatedContent,
                    currentElement: response.data.responseData,
                    currentContent: response.data.responseData.relatedContent[0]
                })
                this._isMount && this.recognizeKeyDown();
                this._isMount && setTimeout(()=> {
                    this.Controls.activeSlider = 1;
                    this.Controls.activeElement = 0;
                    if (document.getElementById('controls_1')) {
                        document.getElementById('controls_1').classList.add('active-button-detail');
                    }
                }, 10); 
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                ActivationService.reAuthenticate()
                    .then(() => {
                        this.getVideoById(video);
                    })
                    .catch(err => {
                        console.log('reAuthenticate error:', err);
                    })
            }
        })
    }

    getItemDetail = (item) => {
        this._isMount && this.setState({
            currentContent: item
        })
    }

    useControls = (keyPressed) => {

        if (this.onBackButton(keyPressed, 'GoBack')) {
            return;
        }
        
        switch(keyPressed){
            case keyCode.down: 
                if(this.Controls.getCurrentControl() === 0){
                    this.Controls.moveDown('video', 'active-button-detail', 'hover-background');
                    this.hiddenMenu();    
                }
                else if(this.Controls.getCurrentControl() === 1){
                    this.Controls.moveDown('video', 'select-item-carousel', 'active-button-detail');
                }
                break;
            case keyCode.up:
                if(this.Controls.getCurrentControl() === 0){
                    this.Controls.moveUp('video', 'hover-background');
                    this.showMenu();     
                }
                if(this.Controls.getCurrentControl() === 1){
                    this.Controls.moveUp('video', 'hover-background', 'active-button-detail');   
                    this.showMenu();  
                }
                if(this.Controls.getCurrentControl() === 2){
                    this.Controls.moveUp('video', 'active-button-detail', 'select-item-carousel');    
                }
                break;
            case keyCode.left:
                if(this.Controls.getCurrentControl() === 0){
                    if(this.Controls.getCurrentElement() === 1){
                        document.getElementById(this.Controls.getCurrentControl().toString()+'_0').children[0].classList.remove('active-tab');
                    }
                    this.Controls.moveLeft('video', 'hover-background'); 
                }
                else if(this.Controls.getCurrentControl() === 2){
                    this.Controls.moveLeft('video', 'select-item-carousel');
                    this.getItemDetail(this.state.relatedContent[this.Controls.getCurrentElement()]);
                }
                break;
            case keyCode.right:
                if(this.Controls.getCurrentControl() === 0){
                    if(this.Controls.getCurrentElement() === 0){
                        document.getElementById(this.Controls.getCurrentControl().toString()+'_0').children[0].classList.remove('hover-background');
                        document.getElementById(this.Controls.getCurrentControl().toString()+'_0').children[0].classList.add('active-tab');
                    }
                    if(!document.getElementsByClassName('active-tab')[0]){
                        document.getElementById(this.Controls.getCurrentControl().toString()+'_0').children[0].classList.add('active-tab');
                    }
                    this.Controls.moveRight('video', 'hover-background'); 
                }
                else if(this.Controls.getCurrentControl() > 1){
                    this.Controls.moveRight('video', 'select-item-carousel');
                    this.getItemDetail(this.state.relatedContent[this.Controls.getCurrentElement()]);
                }
                break;
            case keyCode.enter:
                if(this.Controls.getCurrentControl() === 0){
                    this.clickOnHeader();
                }
                else if(this.Controls.getCurrentControl() === 1){
                    this.props.history.push(urlRoutes.videoPlayer, {data: this.state.currentElement});
                }
                else if(this.Controls.getCurrentControl() >= 2){
                    this.props.goToItemDetail(this.state.relatedContent[this.Controls.getCurrentElement()]);
                }
                break;
            default:
                return;
        }
    }

    render() {
        return (
            <Fragment>
                {
                    this.props.loading ?
                        <LoaderComponent />
                    :
                    <div className="wrapper-item-detail">
                        <ItemDetailComponent item={this.state.currentElement} />
                        <div className="envelope-button-item" >
                            <div id={"controls_1"} className="wrapper-button-item-detail">
                                {
                                    this.state.currentElement && this.state.currentElement.lastPlayTime ?
                                        "KEEP WATCHING" 
                                    : 
                                        "WATCH"
                                }           
                            </div>
                        </div>
                        <div className="inner-carousel-episodes">
                            <CarouselComponent 
                                id_control={2}
                                mainTitle={this.state.currentElement && this.state.currentElement.seriesEpisodeThumbnail 
                                    ? "Other Episodes" 
                                    : "Related Content"
                                }
                                items={this.state.relatedContent.length !== 0 ? this.state.relatedContent : []}
                                getItemDetail={this.getItemDetail}
                                goToItemDetail={this.props.goToItemDetail}
                                page={"video"}
                            />
                            <div style={{position: 'relative', left: '2%'}}>
                                <ItemDetailComponent item={this.state.currentContent} />
                            </div>
                        </div>
                    </div>
                }
            </Fragment>
        )
    }
}

export default withRouter(VideoWrapperComponent);