import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { urlRoutes } from '../config';
import FeaturedComponent from '../components/Featured/FeaturedComponent';
import SearchComponent from '../components/Search/SearchComponent';
import PaywallComponent from '../components/Paywall/PaywallComponent';
import DeviceSignupComponent from '../components/Device-Signup/DeviceSignupComponent';
import DeviceSigninComponent from '../components/Device-Signin/DeviceSigninComponent';
import GenresComponent from '../components/Genres/GenresComponent';
import ExitScreenComponent from "../components/Exit-Screen/ExitScreenComponent";
import SettingsComponent from '../components/Settings/SettingsComponent';
import SerieComponent from '../components/ItemDetail/Serie/SerieComponent';
import VideoComponent from '../components/ItemDetail/Video/VideoComponent';
import PlaylistComponent from '../components/ItemDetail/Playlist/PlaylistComponent';
import VideoPlayerComponent from '../components/VideoPlayer/VideoPlayerComponent';
import ExploreComponent from '../components/Explore/ExploreComponent';

function RouterWrapper(props){
    return (
        <Router>
            <Route exact path="/" render={() => <Redirect to={urlRoutes.paywall} />} />
            <Route exact path={urlRoutes.paywall} component={ PaywallComponent } />
            <Route exact path={urlRoutes.featured} component={ FeaturedComponent } />
            <Route exact path={urlRoutes.search} component={ SearchComponent } />
            <Route exact path={urlRoutes.deviceSignUp} component={ DeviceSignupComponent } />
            <Route exact path={urlRoutes.deviceSignIn} component={ DeviceSigninComponent } />
            <Route exact path={urlRoutes.genres} component={ GenresComponent} />
            <Route exact path={urlRoutes.exitScreen} component={ ExitScreenComponent } />
            {<Route exact path={urlRoutes.settings} component={ SettingsComponent } />}
            <Route exact path={urlRoutes.series} component={ SerieComponent } />
            <Route exact path={urlRoutes.videos} component={ VideoComponent } />
            <Route exact path={urlRoutes.playlist} component={ PlaylistComponent } />
            <Route exact path={urlRoutes.videoPlayer} component={ VideoPlayerComponent } />
            <Route exact path={urlRoutes.explore} component={ ExploreComponent } />
        </Router>
    )
}

export default RouterWrapper;